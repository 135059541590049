<template :key="key">
  <div>
    <div
      class="Tabs-header w-full flex border-gray-300 border-t border-r border-l"
    >
      <div
        @click="tabs = 'sign-up'"
        class="tab1 cursor-pointer w-1/2 py-1"
        :class="tabs === 'sign-in' ? 'bg-gray-300 hover:bg-gray-500' : ''"
      >
        S'inscrire
      </div>
      <div
        @click="tabs = 'sign-in'"
        class="tab2 w-1/2 cursor-pointer py-1"
        :class="tabs === 'sign-up' ? 'bg-gray-300 hover:bg-gray-500' : ''"
      >
        Se connecter
      </div>
    </div>
    <div
      class="border-gray-300 border-b py-5 border-r border-l"
      v-if="tabs === 'sign-up'"
    >
      <div class="text-4xl font-bold mb-3">Inscrivez-vous</div>
      <div class="flex flex-col justify-center items-center">
        <div class="flex justify-between items-center w-2/3">
          <inputBase
            @input="setFirstname"
            class="w-1/2"
            value=""
            label="Nom"
            inputId="lastname"
            typeInput="text"
          />
          <inputBase
            @input="setLastname"
            class="w-1/2"
            value=""
            label="Prénoms"
            inputId="firstname"
            typeInput="text"
          />
        </div>
        <div class="flex justify-between items-center w-2/3">
          <inputBase
            @input="setUsername"
            class="w-1/2"
            value=""
            label="Nom d'utilisateur"
            inputId="username"
            typeInput="text"
          />
          <inputBase
            @input="setPhone"
            class="w-1/2"
            value=""
            label="Téléphone"
            inputId="phone"
            typeInput="tel"
          />
        </div>

        <div class="flex justify-between items-center w-2/3">
          <inputBase
            @input="setEmail"
            class="w-full"
            value=""
            label="Email"
            inputId="email"
            typeInput="email"
          />
        </div>
        <div class="flex justify-between items-center w-2/3">
          <inputBase
            @input="setPassword"
            class="w-1/2"
            value=""
            label="Mot de passe"
            inputId="password"
            typeInput="password"
          />
          <inputBase
            @input="test"
            class="w-1/2"
            value=""
            label="Confirmer mot de passe"
            inputId="password-conf"
            typeInput="password"
          />
        </div>

        <div class="flex justify-between items-center px-2 mt-2 w-2/3">
          <btnCustom class="w-1/2 mr-2" title="Annuler" />
          <btnCustom
            @click="regiserNew"
            class="w-1/2 ml-2"
            brand
            full
            title="S'inscrire"
          />
        </div>
      </div>
    </div>
    <div
      class="border-gray-300 border-b py-5 border-r border-l"
      v-if="tabs === 'sign-in'"
    >
      <div class="text-4xl font-bold mb-6">Connectez-vous</div>
      <div class="flex items-center">
        <div class="img w-1/2 h-full border-r-2 border-gray-300">
          <img src="../assets/img/login(1).png" alt="" />
        </div>
        <div class="form flex flex-col justify-center items-center p-2 w-1/2">
          <div class="w-full max-w-xs">
            <inputBase
              @input="setUsername"
              value=""
              label="Nom d'utilisateur"
              class="w-full max-w-xs"
              inputId="usename2"
              typeInput="text"
            />
            <inputBase
              @input="setPassword"
              value=""
              class="w-full max-w-xs"
              label="Mot de passe"
              inputId="lastname"
              typeInput="password"
            />

            <btnCustom
              @click="loginNew"
              class="p-2 max-w-xs w-full"
              brand
              full
              title="Se connecter"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import btnCustom from "../components/btn.vue";
import inputBase from "@/components/baseInput.vue";
import { mapActions } from "vuex";
export default {
  name: "Sign",
  components: {
    inputBase,
    btnCustom,
  },
  data() {
    return {
      tabs: "sign-up",
      firstname: "",
      lastname: "",
      username: "",
      password: "",
      email: "",
      phone: "",
      role: 0,
      key: 0,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    if (localStorage.getItem("landsapp")) {
      this.$router.push({ name: "Home" });
    }
  },
  methods: {
    ...mapActions({
      register: "auth/register",
      login: "auth/login",
    }),
    setFirstname(event) {
      this.firstname = event;
    },
    setLastname(event) {
      this.lastname = event;
    },
    setUsername(event) {
      this.username = event;
    },
    setEmail(event) {
      this.email = event;
    },
    setPassword(event) {
      this.password = event;
    },
    setPhone(event) {
      this.phone = event;
    },
    async regiserNew() {
      const data = {
        firstname: this.firstname,
        lastname: this.lastname,
        username: this.username,
        password: this.password,
        email: this.email,
        phone: this.phone,
        role: 0,
      };
      await this.register(data);
      window.location.reload();
      this.key++;
    },
    async loginNew() {
      const data = {
        username: this.username,
        password: this.password,
      };
      await this.login(data);
      window.location.reload();
      this.key++;
    },
  },
};
</script>
