<template>
  <!-- Container -->
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mt-56">
        <div
          v-if="errors.length !== 0"
          class="notification bg-red-300 large margin-bottom-55"
        >
          <h4>Oups! Quelque chose ne va pas 🙂</h4>
          <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
        </div>
        <div id="add-listing" class="separated-form mb-10">
          <div class="add-listing-section margin-top-45 mb-3">
            <!-- Headline -->
            <div class="add-listing-headline">
              <h3><i class="sl sl-icon-docs"></i> Détails</h3>
            </div>

            <div class="row with-forms">
              <!-- Phone -->
              <VSelect
                ref="select"
                name="Type"
                @change="Array.isArray(types) ? (type = $event) : types"
                label="Type"
                :value="type"
                class="col-md-6"
                v-on:input="chooseType"
                :options="types"
                :placeholder="$t('announce.selectType')"
              >
              </VSelect>

              <PhoneInput />

              <!-- Email Address -->
              <div class="col-md-6">
                <h5>{{ $t("announce.title") }}</h5>
                <input
                  :placeholder="$t('announce.titleEx')"
                  v-model="title"
                  type="text"
                />
              </div>
            </div>
            <!-- Row / End -->

            <div class="row with-forms">
              <!-- Phone -->
              <div class="col-md-6">
                <h5>{{ $t("announce.amount") }}</h5>
                <input
                  @keypress="num33"
                  step="1"
                  pattern="^[0-9]"
                  min="0"
                  placeholder="500000"
                  v-model="amount"
                  type="number"
                />
              </div>

              <!-- Email Address -->
              <div class="col-md-6">
                <VSelect
                  ref="select"
                  name="Type"
                  @change="
                    Array.isArray(currencies)
                      ? (currencies = $event)
                      : currencies
                  "
                  label="Devise"
                  :value="amountCurrency"
                  class=""
                  v-on:input="chooseCurrency"
                  :options="currencies"
                  :placeholder="$t('announce.selectDevise')"
                >
                </VSelect>
              </div>
            </div>

            <!-- Description TODO: wysiwig -->

            <div class="form">
              <h5>Description</h5>
              <trumbowyg
                v-model="description"
                :config="config"
                class="form-control"
                name="content"
              ></trumbowyg>
            </div>

            <!-- Section -->
            <div class="add-listing-section margin-top-45">
              <!-- Headline -->
              <div class="add-listing-headline">
                <h3>
                  <i class="sl sl-icon-location"></i>
                  {{ $t("announce.localization") }}
                </h3>
                <div class="flex items-center flex-wrap">
                  <div class="auto-loc">
                    Auto {{ $t("announce.localization") }}
                  </div>
                  <label class="switch"
                    ><input
                      v-model="takegeolocalisation"
                      type="checkbox"
                      onmouseenter="openPopover(event,'tooltip-1')"
                      onmouseleave="openPopover(event,'tooltip-1')"
                      checked /><span class="slider round"></span
                  ></label>

                  <div
                    class="hidden bg-white border-0 mb-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
                    id="tooltip-1"
                  >
                    <div>
                      <div
                        class="bg-white text-gray opacity-75 p-3 mb-0 border border-solid uppercase rounded"
                      >
                        {{ $t("announce.localtooltip") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <!-- Row -->
                <div class="row with-forms">
                  <!-- City -->
                  <div class="col-md-6 flex">
                    <div class="w-1/2 mr-4">
                      <h5>{{ $t("announce.area") }}</h5>
                      <input
                        v-model="area"
                        type="number"
                        placeholder="ex: 500"
                      />
                    </div>
                    <div class="w-1/2 ml-4">
                      <VSelect
                        ref="select"
                        name="Type"
                        @change="
                          Array.isArray(areaUnitTable)
                            ? (type = $event)
                            : areaUnitTable
                        "
                        label="Unit"
                        v-on:input="chooseAreaUnit"
                        :options="areaUnitTable"
                        :placeholder="$t('announce.selectUnit')"
                      >
                      </VSelect>

                      <!--h5>Unité de l'aire</h5>
                      <select
                        class="input-text mt-3"
                        v-model="areaUnit"
                        style="position: relative"
                        data-placeholder="Opening Time"
                      >
                        <option label="Choisir l'unité de l'aire"></option>

                        <option
                          :key="index"
                          v-for="(ar, index) in areaUnitTable"
                          :value="ar.value"
                        >
                          {{ ar.libelle }}
                        </option>
                        <option value="$">Dollars américain ($)</option>
                        <option value="€">Euro (€)</option>
                      </select-->
                    </div>
                  </div>

                  <!-- Address -->
                  <div class="col-md-6">
                    <h5>{{ $t("announce.address") }}</h5>
                    <input
                      v-model="address"
                      type="text"
                      placeholder="ex. 964 School Street"
                    />
                  </div>

                  <!-- City -->
                  <div class="col-md-6">
                    <h5>Longitude</h5>
                    <input v-model="longitude" type="text" />
                  </div>

                  <!-- Zip-Code -->
                  <div class="col-md-6">
                    <h5>Latitude</h5>
                    <input v-model="latitude" type="text" />
                  </div>
                </div>
                <!-- Row / End -->
              </div>
            </div>
            <!-- Section / End -->

            <!-- Section -->
            <div class="add-listing-section margin-top-45">
              <!-- Headline -->
              <div class="add-listing-headline">
                <h3>
                  <i class="sl sl-icon-picture"></i> {{ $t("announce.files") }}
                </h3>
              </div>

              <!-- Dropzone -->
              <div class="submit-section">
                <h5>{{ $t("announce.firstImage") }}</h5>
                <input
                  @change="previewFiles"
                  class="cursor-pointer"
                  ref="upload"
                  type="file"
                />
              </div>

              <div class="submit-section">
                <label for="images" class="ml-2 font-bold text-black">
                  {{ $t("announce.images") }} :</label
                >
                <multipleUpload
                  @input="setImages"
                  class="w-full"
                  value=""
                  label="Photo à la une"
                  inputId="images"
                />
              </div>

              <!--div class="submit-section">
                <label for="images" class="flex font-bold text-black ml-2"
                  >Papiers justificatifs :</label
                >
                <multipleUpload
                  @input="setJust"
                  class="w-full"
                  value=""
                  inputId="images"
                />
              </div-->
            </div>
            <!-- Section / End -->

            <a @click="submitForm" class="button preview flex"
              ><svg
                v-if="load"
                class="animate-spin w-8 h-8 text-black z-50 mr-3 ..."
                viewBox="0 0 24 24"
              >
                <circle
                  xmlns="http://www.w3.org/2000/svg"
                  cx="50"
                  cy="50"
                  fill="none"
                  stroke="#ecfefd"
                  stroke-width="10"
                  stroke-height="10"
                  r="35"
                  stroke-dasharray="164.93361431346415 56.97787143782138"
                  transform="matrix(1,0,0,1,0,0)"
                  style="
                    transform: matrix(1, 0, 0, 1, 0, 0);
                    animation-play-state: paused;
                  "
                /></svg
              >{{ $t("save") }} <i class="fa fa-arrow-circle-right"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Content / End -->
</template>

<script>
import axios from "axios";
//import Select from "../components/select.vue";
//import multipleUpload from "@/components/multipleUpload.vue";
import VSelect from "@/components/VSelect.vue";
import PhoneInput from "@/components/PhoneInput.vue";
import { mapActions } from "vuex";
import { createPopper } from "@popperjs/core";
import * as yup from "yup";
import Trumbowyg from "vue-trumbowyg";
import { defineAsyncComponent } from "vue";
const multipleUpload = defineAsyncComponent(() =>
  import("../components/multipleUpload.vue")
);

// Import editor css
import "trumbowyg/dist/ui/trumbowyg.css";
//import PostForm from "@/components/postForm.vue";

let schema = yup.object().shape({
  title: yup.string().required("Le champ titre est un champ requis"),
  description: yup
    .string()
    .required("Le champ description est un champ requis"),
  address: yup.string().required("Le champ adresse est un champ requis"),
  area: yup
    .number("Le champ aire est un champ requis")
    .required("Le champ aire est un champ requis")
    .positive()
    .integer(),
  image: yup.string().required("Le champ image est un champ requis"),
  images: yup.string().default(""),
  areaUnit: yup
    .string()
    .required("Le champ Unité de l'aire est un champ requis"),
  amountCurrency: yup.string().required("Le champ Devise est un champ requis"),
  amount: yup.number("Le champ montant est un champ requis").positive(),
  longitude: yup.string(),
  latitude: yup.string(),
  type: yup.string().required("Le champ type est un champ requis"),
  owner: yup.number().positive(),
});

export default {
  name: "Sign",
  components: {
    //Select,
    //PostForm,
    multipleUpload,
    Trumbowyg,
    VSelect,
    PhoneInput,
  },
  data() {
    return {
      currencies: [
        { value: "XOF", libelle: "Franc Afrique de l'ouest (XOF)" },
        { value: "$", libelle: "Dollars américain ($)" },
        { value: "€", libelle: "Euro (€)" },
      ],
      areaUnitTable: [
        { value: "m2", libelle: "Mètre carré" },
        { value: "dam2", libelle: "Décamètre carré" },
        { value: "hm2", libelle: "Hectomètre carré" },
        { value: "km2", libelle: "Kilomètre carré" },
        { value: "ca", libelle: "Centiare" },
        { value: "da", libelle: "Déciare" },
        { value: "a", libelle: "Are" },
        { value: "daa", libelle: "Décare" },
        { value: "ha", libelle: "Hectare" },
        { value: "acre", libelle: "Acre" },
        { value: "arpent", libelle: "Arpent" },
      ],
      types: [
        { value: "SELL", libelle: "Vente" },
        { value: "RENT", libelle: "Location" },
      ],
      tabs: "sell",
      errors: [],
      option: ["RENT", "SELL", "Auctions"],
      postType: "SELL",
      longitude: "",
      latitude: "",
      takegeolocalisation: false,
      title: "",
      description: "",
      address: "",
      area: "",
      areaUnit: "",
      amount: 0,
      amountCurrency: "",
      image: "",
      images: "",
      justifies: "",
      content: null,
      config: {
        btns: [
          ["viewHTML"],
          ["undo", "redo"], // Only supported in Blink browsers
          ["formatting"],
          ["strong", "em", "del"],
          ["superscript", "subscript"],
          ["link"],
          ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
          ["unorderedList", "orderedList"],
          ["horizontalRule"],
          ["removeformat"],
          ["fullscreen"],
        ],
        load: false,
        changeActiveDropdownIcon: true,
        semantic: {
          b: "strong",
          i: "em",
          s: "del",
          strike: "del",
          div: "p",
        },
        tagClasses: {
          h1: "h1", // Bootstrap example
          blockquote: "bg-grey-100 rounded-xl", // Tailwind CSS example
        },
      },
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("landsapp")) {
      this.$router.push({ name: "Home" });
    }
  },
  watch: {
    takegeolocalisation(newValue) {
      if (newValue) {
        console.log("il se passe quoi?");
        navigator.geolocation.getCurrentPosition(this.showPosition);
      }
    },
  },
  methods: {
    ...mapActions({
      uploadfromStore: "utilities/sigle",
      createPost: "posts/create",
    }),
    openPopover(event, tooltipID) {
      let element = event.target;
      while (element.nodeName !== "BUTTON") {
        element = element.parentNode;
      }
      createPopper(element, document.getElementById(tooltipID), {
        placement: "top",
      });
      document.getElementById(tooltipID).classList.toggle("hidden");
    },
    previewFiles(e) {
      this.setImage(e.target.files[0]);
    },
    async uploadFile(file) {
      console.log("file", file);
      let formData = new FormData();
      formData.append("image", file);
      for (var p of formData) {
        console.log(p);
      }
      return await axios
        .post(
          process.env.VUE_APP_API_BASE_URL + "/uploads/multiples",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              responseType: "json",
            },
          }
        )
        .then((response) => {
          console.log("response---", response.data);
          if (response.status == 201) {
            return response.data[0].filename;
          }
        });
    },
    async setImage(event) {
      console.log("event", event);
      this.image = await this.uploadFile(event);
      await console.log("this.image", this.image);
    },
    async setImages(event) {
      var string = [];
      var names = "";
      console.log("event", event[0]);
      for (let index = 0; index < event.length; index++) {
        console.log("event[index]", event[index].name);
        string[index] = await this.uploadFile(event[index]);
        //await names.concat('#', event[index].name)
        if (this.files === "") {
          this.files += event[index].name;
        } else {
          this.files += "#" + event[index].name;
        }

        console.log("names", this.files);
      }
      /*this.images = await event.map(async (element, index) => {
        console.log("element", string);
        string[index] = await this.uploadFile(element);
        return await this.uploadFile(element);
        // await Promise.resolve(this.uploadFile(element).then(() => {},() => {},res => res))
      });
      /*await event.forEach(async (element) => {
        const int = this.images;
        console.log("int", int);
        string.concat("#", await this.uploadFile(element));
        console.log("this.image", string);
      });*/

      setTimeout(() => {
        console.log("this.image2", names);
      }, 5000);
    },

    chooseType(e) {
      console.log("la valeur", e);
      this.type = e;
    },

    chooseCurrency(e) {
      console.log("la valeur", e);
      this.amountCurrency = e;
    },

    chooseAreaUnit(e) {
      console.log("la valeur", e);
      this.areaUnit = e;
    },

    async setJust(event) {
      console.log("event", event);
      this.images = await event.map(async (element) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(this.uploadFile(element));
          }, 300);
        });
        // await Promise.resolve(this.uploadFile(element).then(() => {},() => {},res => res))
      });
      event.forEach(async (element) => {
        const int = this.justifies;
        console.log("int", int);
        this.justifies.concat("#", await this.uploadFile(element));
        console.log("this.image", this.justifies);
      });
    },

    takeGeo(e) {
      this.takegeolocalisation =
        typeof e === "boolean" ? e : this.takegeolocalisation;
      if (typeof e === "boolean" && e == true) {
        console.log("il se passe quoi?");
        navigator.geolocation.getCurrentPosition(this.showPosition);
      }
    },
    showPosition(position) {
      console.log(position.coords, "position.coords-----");
      this.longitude = position.coords.longitude;
      this.latitude = position.coords.latitude;
      console.log(position.coords, "position.coords");
    },
    num33(event) {
      console.log("----event", event);
      return event.charCode == 8 || event.charCode == 0 || event.charCode == 13
        ? null
        : event.charCode >= 48 && event.charCode <= 57;
    },
    async submitForm() {
      console.log(this.images, "images");
      if (!this.load) {
        this.load = true;

        console.log(this.images[0], "images");
        //let images = "";
        /*this.images.forEach((element) => {
        console.log(element.fulfilled, "element");
        images.concat(element);
        images.concat(";");
        console.log("images", images);
      });*/
        console.log("this.$store.state.auth", this.$store.state.auth);
        const data = {
          title: this.title,
          description: this.description,
          address: this.address,
          area: this.area,
          amountCurrency: this.amountCurrency,
          areaUnit: this.areaUnit,
          image: this.image,
          images: this.files,
          justifies: this.justifies,
          amount: this.amount,
          longitude: this.longitude,
          latitude: this.latitude,
          type: this.type,
          owner: this.$store.state.auth.userId,
        };
        this.errors = [];
        schema
          .validate(data, { abortEarly: false })
          .then(async (valid) => {
            if (valid) {
              await this.createPost(data).then(async (response) => {
                console.log(response, "post");
                this.$router.push({
                  name: "postDetails",
                  params: { id: localStorage.getItem("land.postId") },
                });
              });
            }
          })
          .catch((err) => {
            this.load = false;
            window.scrollTo(0, 0);
            err.inner.forEach((e) => {
              //console.log(e.message, e.path);
              this.errors.push(e.message);
            });
          });
      }
    },
  },
  computed: {
    id() {
      return this.$store.state.posts.current.id;
    },
  },
};
</script>

<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-outer-spin-button
  input[type="number"]::-webkit-innser-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.auto-loc {
  display: none;
}
@media screen and (min-width: 600px) {
  .auto-loc {
    position: absolute;
    right: 140px;
    display: block;
    top: 30px;
    z-index: 100;
  }
}
</style>
