<template>
  <!-- Container -->

  <div class="container flachi">
    <div class="row">
      <div class="col-lg-12 mt-56">
        <div class="mt-4">
          <span class="mt-4" style="font-size: 26px"
            >{{ $t("advisors.introduction") }}
          </span>
        </div>

        <div class="mt-4">
          <span class="mt-4" style="font-size: 26px"
            >{{ $t("advisors.fillIn") }}
          </span>
        </div>
        <div
          v-if="errors.length !== 0"
          class="notification bg-red-300 large margin-bottom-55"
        >
          <h4>Oups! Quelque chose ne va pas 🙂</h4>
          <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
        </div>
        <div id="add-listing" class="separated-form mb-10">
          <div class="add-listing-section margin-top-45 mb-3">
            <!-- Headline -->
            <div class="add-listing-headline">
              <h3><i class="sl sl-icon-docs"></i> {{ $t("details") }}</h3>
            </div>

            <div class="row with-forms">
              <!-- Email Address -->
              <div class="col-md-6">
                <h5>{{ $t("advisors.ni") }}</h5>
                <input
                  @keypress="num33"
                  style="border-color: rgb(0 0 0)"
                  step="1"
                  pattern="^[0-9]"
                  min="0"
                  placeholder=""
                  v-model="ni"
                  type="number"
                />
              </div>

              <!-- Email Address -->
              <div class="col-md-6">
                <h5>{{ $t("advisors.pp") }}</h5>
                <input
                  @keypress="num33"
                  style="border-color: rgb(0 0 0)"
                  step="1"
                  pattern="^[0-9]"
                  min="0"
                  placeholder=""
                  v-model="phosphore"
                  type="number"
                />
              </div>
            </div>
            <!-- Row / End -->

            <div class="row with-forms">
              <!-- Phone -->
              <div class="col-md-6">
                <h5>{{ $t("advisors.k") }}</h5>
                <input
                  @keypress="num33"
                  style="border-color: rgb(0 0 0)"
                  step="1"
                  pattern="^[0-9]"
                  min="0"
                  placeholder=""
                  v-model="potassium"
                  type="number"
                />
              </div>

              <!-- Email Address -->
              <div class="col-md-6">
                <h5>{{ $t("advisors.mg") }}</h5>
                <input
                  @keypress="num33"
                  style="border-color: rgb(0 0 0)"
                  step="1"
                  pattern="^[0-9]"
                  min="0"
                  placeholder=""
                  v-model="magnesium"
                  type="number"
                />
              </div>

              <div class="col-md-6">
                <h5>{{ $t("advisors.pH") }}</h5>
                <input
                  @keypress="num33"
                  style="border-color: rgb(0 0 0)"
                  step="1"
                  pattern="^[0-9]"
                  min="0"
                  placeholder=""
                  v-model="ph"
                  type="number"
                />
              </div>

              <!-- Email Address -->
              <div class="col-md-6">
                <h5>{{ $t("advisors.tem") }}</h5>
                <input
                  @keypress="num33"
                  style="border-color: rgb(0 0 0)"
                  step="1"
                  pattern="^[0-9]"
                  min="0"
                  placeholder=""
                  v-model="temperature"
                  type="number"
                />
              </div>

              <div class="col-md-6">
                <h5>{{ $t("advisors.pl") }}</h5>
                <input
                  @keypress="num33"
                  style="border-color: rgb(0 0 0)"
                  step="1"
                  pattern="^[0-9]"
                  min="0"
                  placeholder=""
                  v-model="pluviometrie"
                  type="number"
                />
              </div>

              <!-- Email Address -->
              <div class="col-md-6">
                <h5>{{ $t("advisors.hum") }}</h5>
                <input
                  @keypress="num33"
                  style="border-color: rgb(0 0 0)"
                  step="1"
                  pattern="^[0-9]"
                  min="0"
                  placeholder=""
                  v-model="humidite"
                  type="number"
                />
              </div>
            </div>

            <div class="row flex-bt" :key="iter">
              <div class="col-md-6 col-sm-6 mb-11">
                <a
                  @click="submitForm"
                  style="font-size: 26px"
                  class="button preview flex"
                  ><svg
                    v-if="load"
                    class="animate-spin w-8 h-8 text-black z-50 mr-3 ..."
                    viewBox="0 0 24 24"
                  >
                    <circle
                      xmlns="http://www.w3.org/2000/svg"
                      cx="50"
                      cy="50"
                      fill="none"
                      stroke="#ecfefd"
                      stroke-width="10"
                      stroke-height="10"
                      r="35"
                      stroke-dasharray="164.93361431346415 56.97787143782138"
                      transform="matrix(1,0,0,1,0,0)"
                      style="
                        transform: matrix(1, 0, 0, 1, 0, 0);
                        animation-play-state: paused;
                      "
                    /></svg
                  >{{ $t("culture") }} <i class="fa fa-arrow-circle-right"></i
                ></a>
              </div>

              <div class="col-md-6 col-sm-6 flex h-full text-center">
                <div v-if="resp" style="font-size: 22px; color: #f91942">
                  <span>
                    {{ $t("advisors.result") }}
                    <strong> {{ resp }} </strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <span class="mt-4" style="font-size: 26px"
            >{{ $t("advisors.geoLoc") }}
          </span>
          <span class="font-bold text-4xl red-orange-brown mb-4 px-10">{{
            $t("advisors.commingSoon")
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- Content / End -->
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { createPopper } from "@popperjs/core";
import * as yup from "yup";

let schema = yup.object().shape({
  ni: yup
    .number("Le champ nitrogène est un champ requis")
    .required("Le champ nitrogène est un champ requis")
    .positive(),
  phosphore: yup
    .number("Le champ phosphore est un champ requis")
    .required("Le champ phosphore est un champ requis")
    .positive(),
  potassium: yup
    .number("Le champ potassium est un champ requis")
    .required("Le champ potassium est un champ requis")
    .positive(),
  magnesium: yup
    .number("Le champ magnesium est un champ requis")
    .required("Le champ magnesium est un champ requis")
    .positive(),
  ph: yup
    .number("Le champ pH est un champ requis")
    .required("Le champ pH est un champ requis")
    .positive(),
  temperature: yup
    .number("Le champ temperature est un champ requis")
    .required("Le champ temperature est un champ requis")
    .positive(),
  pluviometrie: yup
    .number("Le champ pluviometrie est un champ requis")
    .required("Le champ pluviometrie est un champ requis")
    .positive(),
  humidite: yup
    .number("Le champ humidite est un champ requis")
    .required("Le champ humidite est un champ requis")
    .positive(),
});

export default {
  name: "Sign",
  components: {},
  data() {
    return {
      errors: [],
      ni: null,
      phosphore: null,
      potassium: null,
      magnesium: null,
      ph: null,
      temperature: null,
      pluviometrie: null,
      humidite: null,
      resp: null,
      load: false,
      iter: 0,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.addLogs({ request: "landAdvisor" });
  },
  methods: {
    ...mapActions({
      addLogs: "auth/addLogs",
      uploadfromStore: "utilities/sigle",
      createPost: "posts/create",
    }),
    openPopover(event, tooltipID) {
      let element = event.target;
      while (element.nodeName !== "BUTTON") {
        element = element.parentNode;
      }
      createPopper(element, document.getElementById(tooltipID), {
        placement: "top",
      });
      document.getElementById(tooltipID).classList.toggle("hidden");
    },

    async submitForm() {
      if (!this.load) {
        this.load = true;
        this.iter++;

        const data = {
          ni: this.ni,
          phosphore: this.phosphore,
          potassium: this.potassium,
          magnesium: this.magnesium,
          ph: this.ph,
          temperature: this.temperature,
          pluviometrie: this.pluviometrie,
          humidite: this.humidite,
        };
        const dataToLine = [
          parseFloat(data.ni),
          parseFloat(data.phosphore),
          parseFloat(data.potassium),
          parseFloat(data.magnesium),
          parseFloat(data.ph),
          parseFloat(data.temperature),
          parseFloat(data.pluviometrie),
          parseFloat(data.humidite),
        ];
        this.errors = [];

        schema
          .validate(data, { abortEarly: false })
          .then(async (valid) => {
            if (valid) {
              await axios
                .post(process.env.VUE_APP_DATA_API_BASE_URL, dataToLine, {
                  headers: {
                    "Content-Type": "application/json",
                    responseType: "json",
                  },
                })
                .then((response) => {
                  this.resp = response.data;
                  alert(this.$t("advisors.result") + this.resp);
                });
            }
          })
          .catch((err) => {
            this.load = false;
            window.scrollTo(0, 0);
            err.inner.forEach((e) => {
              console.log(e.message, e.path);
              this.errors.push(e.message);
            });
          });
      }
      this.load = false;
    },
  },
};
</script>

<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.flex-bt {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-outer-spin-button
  input[type="number"]::-webkit-innser-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.flachi {
  color: var(--color--body-foreground);
  filter: sepia(0.6);
  display: grid;
  place-items: center;
  min-height: 100vh;
  padding: 2rem;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.auto-loc {
  display: none;
}

.red-orange-brown {
  background-image: linear-gradient(
    to right,
    green,
    yellowgreen 33%,
    yellow 66%,
    gold
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
@media screen and (min-width: 600px) {
  .auto-loc {
    position: absolute;
    right: 140px;
    display: block;
    top: 30px;
    z-index: 100;
  }
}
</style>
