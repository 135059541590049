<template>
  <div class="pt-96 px-8 md:px-0 xl:pt-72">
    <div class="text-4xl mb-6 font-bold">
      Veuillez changer votre mot de passe
    </div>

    <div v-if="error" class="notification bg-red-300 large margin-bottom-55">
      <h4>Oups! Quelque chose ne va pas 🙂. Veuillez réessayer</h4>
    </div>

    <div class="flex flex-col md:flex-row justify-between">
      <p class="form-row form-row-wide mr-4 w-full md:w-1/2">
        <label for="password1"
          >Mot de passe :
          <i class="im im-icon-Lock-2"></i>
          <input
            v-model="password"
            class="input-text"
            type="password"
            name="password1"
            @focus="passwordFocus = true"
            id="password1"
          />
        </label>
        <small
          v-if="passwordFocus"
          id="passwordHelp"
          class="form-text text-muted"
          >Le mot de passe doit contenir au moins
          <div :class="has_minimum_lenth ? 'text-green-500' : 'text-red-400'">
            8 caractères
          </div>
          <div :class="has_lowercase ? 'text-green-500' : 'text-red-400'">
            Une lettre en minuscule
          </div>
          <div :class="has_uppercase ? 'text-green-500' : 'text-red-400'">
            Une lettre majuscule
          </div>
          <div :class="has_number ? 'text-green-500' : 'text-red-400'">
            un chiffre
          </div>
          et
          <div :class="has_special ? 'text-green-500' : 'text-red-400'">
            un caractère spécial.
          </div></small
        >
      </p>

      <p class="form-row form-row-wide md:ml-4 w-full md:w-1/2">
        <label for="password2"
          >Répéter le mot de passe:
          <i class="im im-icon-Lock-2"></i>
          <input
            v-model="passwordRep"
            class="input-text"
            type="password"
            name="password"
            @focus="passwordRepFocus = true"
            id="password"
          />
        </label>
        <small
          v-if="passwordRepFocus"
          id="passwordHelp"
          class="form-text text-muted"
          :class="repeted ? 'text-green-500' : 'text-red-400'"
          >Les deux mots de passe doivent être identiques
        </small>
      </p>
    </div>

    <div class="md:flex justify-between" @click="recorver = false">
      <div></div>
      <input
        type="submit"
        class="button border fw margin-top-10 w-full mb-9 sm:w-1/2 md:w-1/3 flex flex-wrap"
        name="register"
        value="Changer le mot de passe"
        @click.prevent="recover"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    token: { require: true },
  },
  data() {
    return {
      passwordFocus: false,
      passwordRep: "",
      password: "",
      has_minimum_lenth: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      repeted: false,
      error: false,
    };
  },
  watch: {
    password() {
      this.has_minimum_lenth = this.password.length > 8;
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#$%^&*)(+=._-]/.test(this.password);
    },
    passwordRep() {
      if (this.password === this.passwordRep) {
        this.repeted = true;
      } else {
        this.repeted = false;
      }
    },
  },
  methods: {
    ...mapActions({
      rec: "auth/recover",
    }),
    async recover() {
      if (this.repeted) {
        const res = await this.rec({
          token: this.token,
          password: this.password,
        });

        console.log("res", res);

        if (res.isSuccessful) {
          location.href = "/";
        } else {
          this.error = true;
        }
      }
    },
  },
};
</script>
