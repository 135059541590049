<template>
  <div class="custom-select">
    <div
      class="options_container w-full focus-within:border-Tint-30 h-10 rounded border input-text mb-36 select-none"
      @mousedown="toggleDropdown"
    >
      <span class="text-2xl text-black font-bold">{{ label }}</span>
      <div
        class="flex border-2 border-input rounded shadow-sm"
        style="margin-top: 17px; border: 1px solid #dbdbdb; height: 51px"
      >
        <div class="inline-block w-full">
          <div
            class="selected_area h-full inline border-input border items-center justify-center"
          ></div>
          <slot name="search">
            <input
              ref="searchInput"
              style="border: none; box-shadow: none; height: 45px"
              type="text"
              v-model="searchValue"
              class="rounded border-none px-2 w-full focus:outline-none"
              v-bind="inputSearch.attributes"
              v-on="inputSearch.events"
            />
          </slot>
        </div>
        <div class="indacator-append h-full">
          <slot name="indicator">
            <div class="h-full flex items-center">
              <div class="mr-8 relative">
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.712087 0.524587C1.0782 0.158471 1.6718 0.158471 2.03791 0.524587L7 5.48667L11.9621 0.524587C12.3282 0.158471 12.9218 0.158471 13.2879 0.524587C13.654 0.890704 13.654 1.4843 13.2879 1.85041L7.66291 7.47541C7.2968 7.84153 6.7032 7.84153 6.33709 7.47541L0.712087 1.85041C0.345971 1.4843 0.345971 0.890704 0.712087 0.524587Z"
                    fill="#C6D6EC"
                  />
                </svg>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="relative" v-if="dropdownOpen">
        <div
          class="drop-down border z-10 absolute w-full bg-white border-btnBorder mt-2 rounded max-h-64 overflow-y-scroll"
        >
          <template v-if="noDataAvalaible">
            <component class="pl-2 py-2 text-gray-200 text-base" :is="tag">
              {{ noData }}
            </component>
          </template>
          <template v-else>
            <ul class="list-none">
              <li
                v-for="(option, i) of filteredOptions"
                :key="i"
                class="hover:bg-Grey-95 px-6 py-3 cursor-pointer"
                @mousedown.prevent="select(option)"
              >
                <slot name="option">
                  {{ getOptionLabel(option, label) }}
                </slot>
              </li>
              <li>
                <slot name="prepend-option"></slot>
              </li>
            </ul>
          </template>
        </div>
      </div>
    </transition>

    <div
      v-if="dropdownOpen"
      style="width: 93%"
      class="shadow-lg absolute z-50 bg-white overflow-y-scroll rounded-md"
    >
      <div
        @mousedown="
          $emit('input', option.value);
          searchValue = option.libelle;
          open = false;
        "
        v-for="(option, index) in options"
        :key="index"
        class="hover:bg-gray-200 px-5 py-2 rounded-md"
      >
        {{ option.libelle }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },

    tag: {
      type: String,
      default: "div",
    },

    label: {
      type: String,
      default: "label",
    },

    name: {
      type: String,
      default: "Name",
    },

    value: {},

    options: {
      type: Array,
      default() {
        return [];
      },
    },

    reduce: {
      type: Function,
      default: (option) => option,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    searchable: {
      type: Boolean,
      default: true,
    },

    clearSearchOnSelect: {
      type: Boolean,
      default: true,
    },

    closeOnSelect: {
      type: Boolean,
      default: true,
    },

    getOptionLabel: {
      type: Function,
      default(option, label) {
        const hasOwnProperty = Object.prototype.hasOwnProperty;
        if (typeof option === "object") {
          if (!hasOwnProperty.call(option, label)) {
            return console.warn(
              `[vue-select warn]: Label key "option.${label}" does not` +
                ` exist in options object ${JSON.stringify(option)}.\n`
            );
          }
          return option[label];
        }
        return option;
      },
    },

    overWriteOptionLabel: {
      type: Function,
      default: ({ option, label }, getOptionLabel) =>
        getOptionLabel(option, label),
    },

    getOptionKey: {
      type: Function,
      default(option) {
        if (typeof option !== "object") {
          return option;
        }
        const hasOwnProperty = Object.prototype.hasOwnProperty;
        try {
          return hasOwnProperty.call(option, "id")
            ? option.id
            : this.sortAndStringify(option);
        } catch (e) {
          const warning =
            `Could not stringify this option ` +
            `to generate unique key. Please provide'getOptionKey' prop ` +
            `to return a unique key for each option.\n`;
          return console.warn(warning, option, e);
        }
      },
    },

    filterBy: {
      type: Function,
      default(option, label, search) {
        const lab = label || "";
        // console.log("filterBy", search);

        if (this.caseSensitive) {
          return lab.indexOf(search) > -1;
        }
        return lab.toLowerCase().indexOf(search.toLowerCase()) > -1;
      },
    },

    filter: {
      type: Function,
      default(options, search) {
        return options.filter((option) => {
          let label = this.getOptionLabel(option, this.label);
          if (typeof label === "number") {
            label = label.toString();
          }
          return this.filterBy(option, label, search);
        });
      },
    },

    searchInputQuerySelector: {
      type: String,
      default: "[type=search]",
    },
  },

  data() {
    return {
      open: false,
      noData: "No Data available",
      privateValue: [],
      searchValue: "",
    };
  },

  watch: {
    selectedValue: {
      // immediate: true,
      handler(val) {
        // console.log("change ", val);
        this.updateValue(val);
      },
    },
  },

  methods: {
    select(option) {
      // console.log("Selected option ", option);

      if (!this.isOptionSelected(option)) {
        if (this.multiple) {
          this.selectedValue.push(option);
        } else {
          if (this.isEmptySelected) {
            // console.log("Push selected ", option);
            this.privateValue.push(option);
          } else {
            this.privateValue = [].concat([option]);
          }
        }
        this.onAfterSelect(option);

        return;
      } else {
        this.onAfterSelect(option);
      }
    },

    onAfterSelect(option) {
      console.log("onAfterSelect.option", option);
      if (!this.multiple) {
        if (this.closeOnSelect) {
          this.close();
        }
      }

      if (this.clearSearchOnSelect) {
        this.searchValue = "";
      }
    },

    updateValue(options) {
      let value;
      if (this.multiple) {
        value = options.map((val) => this.reduce(val.value));
      } else {
        if (Array.isArray(options) && options.length > 0) {
          value = this.reduce(options[0]);
        } else {
          switch (this.typeofReduce) {
            case "object":
              value = {};
              break;

            default:
              value = "";
              break;
          }
        }
      }
      // console.log("change ", value);

      this.$emit("change", value);
      this.$emit("input", value);
    },

    sortAndStringify(sortable) {
      const ordered = {};

      Object.keys(sortable)
        .sort()
        .forEach((key) => {
          ordered[key] = sortable[key];
        });
      // console.log("JSON.stringify ", JSON.stringify(ordered));

      return JSON.stringify(ordered);
    },

    isOptionSelected(option) {
      return this.selectedValue.some((value) =>
        this.optionComparator(value, option)
      );
    },

    optionComparator(a, b) {
      return this.getOptionKey(a) === this.getOptionKey(b);
    },

    toggleDropdown(event) {
      console.log("toggleDropdown.event", event);
      this.open = !this.open;
    },

    onSearchFocus(e) {
      console.log("focus ", e);
    },

    onSearchBlur(e) {
      console.log("blur ", e);
      this.open = false;
    },

    closeSearchOptions() {
      this.open = false;
    },

    close() {
      // if (this.$refs.searchInput && this.$refs.searchInput.hasFocus()) {
      //   this.$refs.searchInput.blur();
      // } else {
      //   this.open = false;
      // }
      // console.log("searchInput ", this.$refs.searchInput);

      this.open = false;
    },

    findOptionFromReducedValue(value) {
      const comparator = (option) =>
        JSON.stringify(this.reduce(option)) === JSON.stringify(value);

      const matches = [...this.options].filter(comparator);

      if (matches.length === 1) {
        return matches[0];
      }

      return (
        matches.find((match) =>
          this.optionComparator(match, this.privateValue)
        ) || value
      );
    },

    setInitialValue(value) {
      if (Array.isArray(value)) {
        this.privateValue = value.map((val) =>
          this.findOptionFromReducedValue(val)
        );
      } else if (typeof value === "object" && this.isEmptyObject(value)) {
        this.privateValue = [];
      } else {
        this.privateValue = [].concat(this.findOptionFromReducedValue(value));
      }
    },

    reset() {
      this.privateValue = [];
    },

    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },

    hasValue() {
      return this.privateValue.length > 0;
    },
  },

  computed: {
    optionList() {
      return this.options;
    },

    filteredOptions() {
      const optionList = [].concat(this.optionList);

      const options =
        this.searchValue.length > 0
          ? this.filter(optionList, this.searchValue)
          : optionList;
      return options;
    },

    selectedValue() {
      return this.privateValue;
    },

    inputSearch() {
      return {
        attributes: {
          disabled: this.disabled,
          placeholder: this.shouldDisplayPlaceholder,
          readonly: !this.searchable,
        },
        events: {
          blur: this.onSearchBlur,
          focus: this.onSearchFocus,
          input: (e) => (this.searchValue = e.target.value),
          submit: (e) => {
            console.log("submit event on select_______ ", e);
          },
          reset: (e) => {
            console.log("reset event on select_______ ", e);
          },
        },
      };
    },

    inputStyle() {
      const length =
        this.searchValue.length === 0 ? 1 : this.searchValue.length;
      const style = {
        width: this.isEmptySelected ? "100%" : length + "ch",
      };
      return style;
    },

    shouldDisplayPlaceholder() {
      return this.isEmptySelected && this.placeholder;
    },

    isEmptySelected() {
      return this.selectedValue.length === 0;
    },

    dropdownOpen() {
      return this.open;
    },

    typeofReduce() {
      let type = typeof "";
      if (this.options[0]) {
        type = typeof this.options[0];
      }
      return type;
    },

    noDataAvalaible() {
      return this.filteredOptions.length === 0;
    },
  },

  created() {
    if (this.value && this.value != null) {
      // console.log("setInitialValue__", this.value);

      this.setInitialValue(this.value);
    } /* else {
      console.log("No initialValue");
    } */
  },

  // updated() {
  //   console.log("update custom select ====>", this.selectedValue);
  // }
};
</script>

<style></style>
