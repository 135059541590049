export default {
  // :style=" banque_form==true ? `border: 2px solid ${headerStyle.borderColor}` : 'yy'
  header: {
    background: "#4e6bfc",
  },

  activePaymentColor: {
    color: "#4e6bfc",
  },

  headerTitle: {
    color: "white",
    fontSize: "16px",
    marginTop: "6px",
    marginBottom: "-5px",
    lineHeight: "16px",
  },

  headerAmount: {
    fontWeight: "bold",
  },

  headerDmountDescription: {
    background: "rgba(0, 0, 0, 0.1)",
  },

  paymentMethods: {
    background: "rgba(95, 60, 151, 0.1)",
  },

  valiadateNotif: {},

  paymentMethodsSelectedText: {},

  paymentMethodsChecked: {
    border: "solid",
    borderColor: "#4e6bfc",
    borderWidth: "2px",
    // border: 2px solid #F11C33;
  },

  paymentMethodsChecked_: {},

  FormWrapper: {},

  FormInputLabel: {},

  FormInputLabelInFocus: {
    // color: "#4e6bfc",
    // borderColor: "#4e6bfc"
  },

  FormInputFocus: {
    borderColor: "#4e6bfc",
    color: "#4e6bfc",
  },

  button: {
    background: "#4e6bfc",
    fontWeight: "900",
    color: "#4e6bfc",
  },

  errorBadge: {
    background: "#faeded",
  },

  footer: {
    background: "#EEF1F5",
  },

  pendingHeader: {
    background: "#4e6bfc",
  },
};
