<template>
  <div id="header" style="position: fixed" class="fixed">
    <div
      v-if="mobile"
      class="mobile bg-black absolute lg:hidden -top-5 w-3/4 h-screen"
      style="z-index: 22222222"
    >
      <nav class="" data-v-7ba5bd90="" id="mm-0" style="z-index: 22222222">
        <div class="mm-panels">
          <div class="mm-panel mm-hasnavbar mm-opened" id="mm-1">
            <div class="mm-navbar"><a class="mm-title">Menu</a></div>
            <ul @click="mobile = false" data-v-7ba5bd90="" class="mm-listview">
              <li data-v-7ba5bd90="">
                <a
                  href="#/"
                  class="text-2xl font-bold"
                  data-v-7ba5bd90=""
                  style="font-size: 20px"
                  >{{ $t("home.nav.home") }}</a
                >
              </li>
              <li data-v-7ba5bd90="">
                <a
                  href="#/announces"
                  class="mr-3 font-bold"
                  data-v-7ba5bd90=""
                  style="font-size: 20px"
                  >{{ $t("home.nav.Announces") }}</a
                >
              </li>
              <li data-v-7ba5bd90="">
                <a
                  href="#/auction"
                  class="mr-3 font-bold"
                  data-v-7ba5bd90=""
                  style="font-size: 20px"
                  >{{ $t("home.nav.Auction") }}</a
                >
              </li>

              <li>
                <a
                  class="mr-3 font-bold"
                  style="font-size: 20px"
                  href="#/lands-advisor"
                  >{{ $t("home.nav.LandsAdvisor") }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div class="container">
      <!-- Left Side Content -->
      <div class="left-side">
        <!-- Logo -->
        <div id="logo">
          <a href="/"
            ><img
              src="images/White-logo-nobackground.png"
              data-sticky-logo="images/White-logo-nobackground.png"
              alt=""
          /></a>
        </div>

        <!-- Mobile Navigation -->
        <div class="cursor-pointer lg:hidden" @click="bibouille">
          <button
            class="hamburger opacity-100 hamburger--collapse"
            type="button"
          >
            <div class="bg-white w-14 rounded h-2 mb-2"></div>
            <div class="bg-white w-14 rounded h-2 mb-2"></div>
            <div class="bg-white w-14 rounded h-2"></div>
          </button>
        </div>

        <!-- Main Navigation -->
        <nav id="navigation" class="style-1">
          <ul id="responsive">
            <li>
              <router-link
                class="text-2xl font-bold"
                style="font-size: 20px"
                to="/"
                >{{ $t("home.nav.home") }}</router-link
              >
            </li>

            <li>
              <router-link
                class="mr-3 font-bold"
                style="font-size: 20px"
                to="/announces"
                >{{ $t("home.nav.Announces") }}</router-link
              >
            </li>

            <li v-if="authenticated">
              <router-link
                class="mr-3 font-bold"
                style="font-size: 20px"
                to="/favorites"
                >{{ $t("home.nav.favorites") }}</router-link
              >
            </li>

            <li>
              <router-link
                class="mr-3 font-bold"
                style="font-size: 20px"
                to="/auction"
                >{{ $t("home.nav.Auction") }}</router-link
              >
            </li>

            <li>
              <router-link
                class="mr-3 font-bold"
                style="font-size: 20px"
                to="/lands-advisor"
                >{{ $t("home.nav.LandsAdvisor") }}</router-link
              >
            </li>
          </ul>
        </nav>
        <div class="clearfix"></div>
        <!-- Main Navigation / End -->
      </div>
      <!-- Left Side Content / End -->

      <!-- Right Side Content / End -->
      <div class="right-side">
        <div
          class="header-widget flex items-center justify-between lg:justify-end"
        >
          <!-- User Menu -->
          <div
            v-if="authenticated"
            @click="openSet"
            class="flex items-center cursor-pointer relative"
          >
            <div class="font-semibold mt-1 text-white text-3xl flex">
              <span
                ><img
                  class="rounded-full mr-4"
                  style="height: 35px; width: 35px"
                  :src="baseUrl + '/' + imageNps"
                  alt=""
              /></span>
              <div class="mt-1 flex justify-center items-center">
                {{ $t("home.nav.Hi") }} {{ firstname }} !
                <svg
                  class="mt- m-4"
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.712087 0.524587C1.0782 0.158471 1.6718 0.158471 2.03791 0.524587L7 5.48667L11.9621 0.524587C12.3282 0.158471 12.9218 0.158471 13.2879 0.524587C13.654 0.890704 13.654 1.4843 13.2879 1.85041L7.66291 7.47541C7.2968 7.84153 6.7032 7.84153 6.33709 7.47541L0.712087 1.85041C0.345971 1.4843 0.345971 0.890704 0.712087 0.524587Z"
                    fill="#C6D6EC"
                  />
                </svg>
              </div>

              <div
                @click="cust = false"
                class="absolute flex flex-col rounded items-start justify-start bg-white top-11"
                v-if="cust"
              >
                <div
                  class="text-black hover:bg-gray-200 p-4 w-full flex justify-start items-start"
                  @click="
                    openSet;
                    $router.push({ name: 'setting' });
                  "
                >
                  {{ $t("home.nav.Account") }}
                </div>
                <div
                  class="text-black hover:bg-gray-200 p-4 w-full"
                  @click="signOut"
                >
                  {{ $t("home.nav.Sign-out") }}
                </div>
              </div>
            </div>
          </div>

          <a
            v-else
            @click="signinMet"
            class="button border mb-4 with-icon font-bold"
            ><i class="im im-icon-Male -mt-24"></i>
            {{ $t("home.nav.Sign-in") }}</a
          >

          <a
            @click="createNew"
            class="button border mb-4 with-icon font-bold md:ml-6"
          >
            {{ $t("home.nav.Add") }} <i class="sl sl-icon-plus"></i
          ></a>
          <div
            href=""
            class="border mb-4 with-icon font-bold flex ml-10 justify-between items-center"
          >
            <select
              style="height: 30px; padding: 0; margin-top: 20px"
              v-model="$i18n.locale"
            >
              <option value="en">En</option>
              <option value="fr">Fr</option>
            </select>
          </div>
        </div>
      </div>

      <!-- Right Side Content / End -->

      <!-- Sign In Popup -->

      <div
        class="fixed inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        v-show="signin"
        style="z-index: 1000"
      >
        <div
          class="flex flex-wrap items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <!-- This element is to trick the browser into centering the modal contents. -->

          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all mt-36 sm:align-middle lg:w-1/2"
          >
            <div class="bg-white px-4 pb-4 sm:px-12 sm:pb-4">
              <div id="" class="bg-white">
                <div class="small-dialog-header">
                  <h3>{{ $t("home.sign.Sign") }}</h3>
                </div>

                <!--Tabs -->
                <div class="sign-in-form style-1 px-4">
                  <ul class="tabs-nav cursor-pointer">
                    <li class="">
                      <a
                        :class="{
                          signInMod: 'border-b-2 border-red-500 text-red-500',
                        }"
                        @click="
                          signInMod = true;
                          signUpMod = false;
                        "
                        >{{ $t("home.nav.Sign-in") }}</a
                      >
                    </li>
                    <li>
                      <a
                        :class="{
                          signUpMod: 'border-b-2 border-red-500 text-red-500',
                        }"
                        @click="
                          signInMod = false;
                          signUpMod = true;
                        "
                        >{{ $t("home.nav.Sign-up") }}</a
                      >
                    </li>
                  </ul>
                  <br />
                  <br />

                  <div class="alt pb-10">
                    <!-- Login -->
                    <div class="" v-if="signInMod" style="">
                      <form method="post" class="login" autocomplete="off">
                        <div
                          class="text-red-500"
                          v-if="errorLogin === 'Username or password failed'"
                        >
                          {{ $t("home.sign.form1.error") }}
                        </div>
                        <div class="md:flex justify-between">
                          <p class="form-row form-row-wide md:mr-4 md:w-1/2">
                            <label for="username">
                              {{ $t("home.sign.form1.username") }}
                              <i class="im im-icon-Male"></i>
                              <input
                                v-model="username"
                                type="text"
                                class="input-text"
                                name="username"
                                id="username"
                              />
                            </label>
                          </p>

                          <p class="form-row form-row-wide md:ml-4 md:w-1/2">
                            <label for="password"
                              >{{ $t("home.sign.form1.password") }}
                              <i class="im im-icon-Lock-2"></i>
                              <input
                                v-model="password"
                                class="input-text"
                                type="password"
                                name="password"
                                id="password"
                              />
                            </label>
                          </p>
                        </div>

                        <p class="form-row form-row-wide">
                          <span class="lost_password cursor-pointer">
                            <a
                              @click="
                                signin = false;
                                recorver = true;
                              "
                              >{{ $t("home.sign.form1.passwordlost") }}</a
                            >
                          </span>
                        </p>

                        <p
                          v-if="errorLogin.length != 0"
                          class="form-row form-row-wide"
                        >
                          <span class="">
                            <div class="text-red-400" href="#">
                              {{ errorLogin }}
                            </div>
                          </span>
                        </p>

                        <div class="form-row">
                          <div class="flex justify-between">
                            <div
                              @click="signin = false"
                              class="border-2 rounded-full flex flex-wrap justify-center items-center cursor-pointer border-red-500 border-solid text-red-500 font-semibold w-1/2 mr-4 margin-top-5"
                            >
                              {{ $t("cancel") }}
                            </div>
                            <input
                              @click.prevent="loginMet"
                              type="submit"
                              class="button border w-1/2 mr-4 margin-top-5"
                              :value="$t('save')"
                            />
                          </div>
                          <div class="checkboxes margin-top-10">
                            <input
                              id="remember-me"
                              type="checkbox"
                              name="check"
                            />
                            <label for="remember-me">{{
                              $t("home.sign.form1.remember")
                            }}</label>
                          </div>
                        </div>
                      </form>
                    </div>

                    <!-- Register -->
                    <div class="" v-if="signUpMod">
                      <form method="post" class="register" autocomplete="off">
                        <div class="md:flex justify-between">
                          <p class="form-row form-row-wide md:mr-4 md:w-1/2">
                            <label for="username2"
                              >{{ $t("home.sign.form2.firstname") }} * :
                              <i class="im im-icon-Male"></i>
                              <input
                                type="text"
                                class="input-text"
                                name="firstname"
                                id="firstname"
                                v-model="firstname"
                              />
                            </label>
                          </p>

                          <p class="form-row form-row-wide md:ml-4 md:w-1/2">
                            <label for="password"
                              >{{ $t("home.sign.form2.lastname") }} * :
                              <i class="im im-icon-Male"></i>
                              <input
                                v-model="lastname"
                                class="input-text"
                                type="text"
                                name="lastname"
                                id="lastname"
                              />
                            </label>
                          </p>
                        </div>

                        <div class="md:flex justify-between">
                          <p class="form-row form-row-wide md:mr-4 md:w-1/2">
                            <label for="email2"
                              >{{ $t("home.sign.form2.email") }} * :
                              <i class="im im-icon-Mail"></i>
                              <input
                                type="text"
                                class="input-text"
                                v-model="email"
                                name="email"
                                id="email2"
                              />
                            </label>
                          </p>

                          <p class="form-row form-row-wide md:ml-4 md:w-1/2">
                            <label for="password"
                              >{{ $t("home.sign.form2.username") }} * :
                              <i class="im im-icon-Male"></i>
                              <input
                                v-model="username"
                                class="input-text"
                                type="text"
                                name="username"
                                id="username"
                              />
                            </label>
                          </p>
                        </div>

                        <div class="md:flex justify-between">
                          <p class="form-row form-row-wide md:mr-4 md:w-1/2">
                            <label for="password1"
                              >{{ $t("home.sign.form1.password") }} * :
                              <i class="im im-icon-Lock-2"></i>
                              <input
                                v-model="password"
                                class="input-text"
                                type="password"
                                name="password1"
                                @focus="passwordFocus = true"
                                id="password1"
                              />
                            </label>
                            <small
                              v-if="passwordFocus"
                              id="passwordHelp"
                              class="form-text text-muted"
                              >{{ $t("home.sign.form2.passwordmust") }}
                              <div
                                :class="
                                  has_minimum_lenth
                                    ? 'text-green-500'
                                    : 'text-red-400'
                                "
                              >
                                8 caractères
                              </div>
                              <div
                                :class="
                                  has_lowercase
                                    ? 'text-green-500'
                                    : 'text-red-400'
                                "
                              >
                                {{ $t("home.sign.form2.downcase") }}
                              </div>
                              <div
                                :class="
                                  has_uppercase
                                    ? 'text-green-500'
                                    : 'text-red-400'
                                "
                              ></div>
                              <div
                                :class="
                                  has_number ? 'text-green-500' : 'text-red-400'
                                "
                              >
                                {{ $t("home.sign.form2.number") }}
                              </div>
                              et
                              <div
                                :class="
                                  has_special
                                    ? 'text-green-500'
                                    : 'text-red-400'
                                "
                              >
                                {{ $t("home.sign.form2.specialChar") }}
                              </div></small
                            >
                          </p>

                          <p class="form-row form-row-wide md:ml-4 md:w-1/2">
                            <label for="password2">
                              {{ $t("home.sign.form2.passwordRep") }} * :
                              <i class="im im-icon-Lock-2"></i>
                              <input
                                v-model="passwordRep"
                                class="input-text"
                                type="password"
                                name="password"
                                @focus="passwordRepFocus = true"
                                id="password"
                              />
                            </label>
                            <small
                              v-if="passwordRepFocus"
                              id="passwordHelp"
                              class="form-text text-muted"
                              :class="
                                repeted ? 'text-green-500' : 'text-red-400'
                              "
                              >{{ $t("home.sign.form2.passwordSame") }}
                            </small>
                          </p>
                        </div>

                        <div class="md:flex justify-between">
                          <p class="form-row form-row-wide md:ml-4 md:w-1/2">
                            <label for="password"
                              >{{ $t("home.sign.form2.phone") }} * :
                              <i class="im im-icon-Phone-3"></i>
                              <input
                                v-model="phone"
                                class="input-text"
                                type="tel"
                                placeholder="ex: (229)97000000"
                                name="phone"
                                id="phone"
                              />
                            </label>
                          </p>
                          <p class="form-row form-row-wide md:ml-4 md:w-1/2">
                            <label for="password"
                              >Profession (Optionnel) :
                              <i class="im im-icon-Lock-2"></i>
                              <select
                                class="input-text mt-3"
                                style="position: relative"
                                v-model="profession"
                                data-placeholder="Opening Time"
                              >
                                <option
                                  label="choisissez votre profession"
                                ></option>
                                <option
                                  v-for="(profession, index) in professions"
                                  :key="index"
                                >
                                  {{ profession }}
                                </option>
                              </select>
                            </label>
                          </p>
                        </div>
                        <div
                          class="flex justify-between"
                          @click="signin = false"
                        >
                          <div
                            class="border-2 rounded-full flex flex-wrap justify-center items-center cursor-pointer border-red-500 text-red-500 font-semibold w-1/2 mr-4 margin-top-5 border-solid"
                            style="border"
                          >
                            {{ $t("cancel") }}
                          </div>
                          <input
                            type="submit"
                            class="button border fw margin-top-10 w-1/2 mb-3"
                            name="register"
                            :value="$t('save')"
                            @click.prevent="registerMet"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Sign In Popup / End -->

      <div
        class="fixed inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        v-show="recorver"
        style="z-index: 1000"
      >
        <div
          class="flex flex-wrap items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all mt-36 sm:align-middle lg:w-1/2"
          >
            <div class="bg-white px-4 pb-4 sm:px-12 sm:pb-4">
              <div id="" class="bg-white">
                <div class="small-dialog-header">
                  <h3>{{ $t("home.sign.form3.recorver") }}</h3>
                </div>

                <!--Tabs -->
                <div class="sign-in-form style-1">
                  <div class="alt pb-10">
                    <!-- Login -->
                    <div class="" id="" style="">
                      <form method="post" class="login" autocomplete="off">
                        <div v-if="recSend" class="text-green-500">
                          {{ $t("home.sign.form3.mailSent") }}
                        </div>
                        <div class="flex justify-between">
                          <p class="form-row form-row-wide mr-4 w-full">
                            <label for="username"
                              >{{ $t("home.sign.form2.email") }}:
                              <i class="im im-icon-Mail"></i>
                              <input
                                v-model="email"
                                type="text"
                                class="input-text"
                                name="email"
                                id="email"
                              />
                            </label>
                          </p>
                        </div>

                        <div class="flex justify-between">
                          <div
                            class="border-2 rounded-full flex flex-wrap justify-center items-center cursor-pointer border-red-500 text-red-500 font-semibold w-1/2 mr-4 margin-top-5 border-solid"
                            @click="recorver = false"
                            style="border"
                          >
                            {{ $t("cancel") }}
                          </div>
                          <input
                            type="submit"
                            class="button border fw margin-top-10 w-1/2 mb-3"
                            name="register"
                            :value="$t('save')"
                            @click.prevent="recover"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <!-- Register -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header / End -->

  <router-view class="md:px-32 pt-28 xl:py-4"> </router-view>

  <div v-if="$route.name !== 'announces'" id="footer" class="sticky-footer">
    <!-- Main -->
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-6 flex">
          <div>
            <img
              class="footer-logo h-44"
              src="images/Color-logo-with-background.png"
              alt=""
            />
            <br /><br />
            <h1 class="text-3xl -mt-6">{{ $t("home.footer.punch") }}</h1>
            <ul style="" class="social-icons ml-44 margin-top-20">
              <li>
                <a class="facebook" href="#"><i class="icon-facebook"></i></a>
              </li>
              <li>
                <a class="twitter" href="#"><i class="icon-twitter"></i></a>
              </li>
              <li>
                <a class="gplus" href="#"><i class="icon-gplus"></i></a>
              </li>
              <li>
                <a class="vimeo" href="#"><i class="icon-vimeo"></i></a>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h4 class="hidden md:block">
            <div>{{ $t("home.footer.links") }}</div>
          </h4>
        </div>

        <div class="col-md-4 col-sm-6 flex flex-wrap justify-between">
          <ul
            class="footer-links flex flex-wrap flex-wrap-col justify-start items-start"
          >
            <li>
              <a class="cursor-pointer" @click="signinMet">{{
                $t("home.nav.Sign-in")
              }}</a>
            </li>
            <li>
              <a
                class="cursor-pointer"
                @click="$router.push({ name: 'setting' })"
                >{{ $t("home.nav.Account") }}</a
              >
            </li>
            <li>
              <a class="cursor-pointer" @click="createNew">{{
                $t("home.nav.Add")
              }}</a>
            </li>
            <li>
              <a class="cursor-pointer" href="blog/details">{{
                $t("home.footer.blog")
              }}</a>
            </li>
            <li>
              <a
                class="cursor-pointer"
                @click="$router.push({ name: 'about' })"
                >{{ $t("home.footer.team") }}</a
              >
            </li>
            <!--li><a class="cursor-pointer" href="#">Nos partenaires</a></li-->
            <li>
              <a class="cursor-pointer" href="mailto:contact@landsapp.com"
                >Contact</a
              >
            </li>
            <!--li><a href="#">Politique</a></li-->
          </ul>

          <ul class="footer-links">
            <!--li><a class="cursor-pointer" href="#">FAQ</a></li-->
            <h4>{{ $t("home.footer.contact") }}</h4>
            <div class="text-widget">
              <span> C/1046 FIYEGNON Cotonou</span> <br />
              Phone: <span>(+229) 66-17-08-50 </span><br />
              E-Mail :
              <span>
                <a href="mailto:contact@landsapp.com">
                  contact@landsapp.com</a
                > </span
              ><br />
            </div>
          </ul>
          <div class="clearfix"></div>
        </div>

        <div class="col-md-4 col-sm-12">
          <div
            v-if="errors.length !== 0"
            class="notification bg-red-300 large margin-bottom-55"
          >
            <h4>Oups! Quelque chose ne va pas 🙂</h4>
            <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
          </div>
          <div class="col-md-12">
            <h4 class="text-3xl font-medium">
              {{ $t("announce.newsLetter") }}
            </h4>
            <input
              min="0"
              placeholder="Email"
              v-model="emailNew"
              type="email"
            />
            <input
              min="0"
              :placeholder="$t('announce.newsLetterName')"
              v-model="name"
              type="text"
            />
            <a
              style="color: white"
              @click="submit"
              class="button preview flex"
              >{{ $t("home.nav.Sign-up") }}</a
            >
          </div>
        </div>
      </div>

      <div
        v-if="success"
        style="z-index: 999999999"
        class="fixed bg-green-600 w-192 h-32 rounded p-5 text-white flex top-4 right-4"
      >
        {{ $t("home.toast.checkEmail") }}
        <div @click="success = false">x</div>
      </div>

      <div
        v-if="deleted"
        style="z-index: 999999999"
        class="fixed bg-green-600 w-192 h-32 rounded p-5 text-white flex justify-between top-4 right-4"
      >
        {{ $t("home.toast.deletedAnnounce") }}
        <div class="cursor-pointer" @click="deleted = false">x</div>
      </div>

      <div
        v-if="errorPass"
        style="z-index: 999999999"
        class="fixed bg-red-500 w-192 h-32 rounded p-5 text-white flex justify-between top-4 right-4"
      >
        {{ $t("home.toast.wrongId") }}
        <div class="cursor-pointer" @click="deleted = false">x</div>
      </div>

      <!-- Copyright -->
      <div class="row">
        <div class="col-md-12">
          <div class="copyrights" v-if="$route.name !== 'announces'">
            © 2021 LandsApp. Tous droits réservés.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as yup from "yup";
//import btnCustom from "./components/btn.vue";
//import SignModel from "./components/SignModel.vue";

let schemaTwo = yup.object().shape({
  email: yup.string().required("Le champ email est un champ requis"),
  name: yup.string().required("Le champ nom et prénom est un champ requis"),
});
export default {
  components: {
    //btnCustom,
    //SignModel
  },
  data() {
    return {
      signin: false,
      username: "",
      password: "",
      passwordRep: "",
      firstname: "",
      lastname: "",
      email: "",
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      phone: "",
      role: 0,
      key: 0,
      has_minimum_lenth: false,
      has_number: false,
      has_lowercsae: false,
      has_uppercase: false,
      has_special: false,
      passwordFocus: false,
      passwordRepFocus: false,
      errorPass: false,
      repeted: false,
      professions: [
        "Acheteur",
        "Administrateur de base de données",
        "Agent de sûreté aéroportuaire",
        "Agent de transit",
        "Agent d'entretien",
        "Agent funéraire",
        "Agent immobilier",
        "Agent de police",
        "Agent de presse",
        "Agent de sécurité",
        "Agent de surveillance de la voie publique (ASVP)",
        "Agronome",
        "Ambulancier",
        "Analyste financier",
        "Analyste programmeur",
        "Analyste Web",
        "Architecte",
        "Architecte paysagiste",
        "Architecte en système d'information",
        "Archiviste",
        "Assitant administratif et financier",
        "Assistant blibliothécaire",
        "Assistant d'éducation",
        "Assistant dentaire",
        "Assistant marketing",
        "Assistant paie et administration",
        "Assistant RH",
        "Assureur",
        "Audioprothésiste",
        "Auditeur financier",
        "Auxiliaire de puériculture",
        "Auxiliaire de vie sociale",
        "Avocat",
        "Bibliothécaire",
        "Biologiste",
        "Chargé de communication",
        "Chargé de recrutement",
        "Charpentier",
        "Chauffeur de taxi",
        "Chefs de chantier",
        "Chef de projet",
        "Chef de projet marketing",
        "Chef d'établissement",
        "Chiropracteur",
        "Chirurgien orthopédiste",
        "Coiffeur",
        "Commis de cuisine",
        "Comptable",
        "Concepteur de jeux vidéos",
        "Concepteur-rédacteur",
        "Conducteur de grue",
        "Conducteur de train",
        "Conseiller",
        "Conseiller d'orientation",
        "Conseiller en insertion professionnelle",
        "Consultant bien-être",
        "Consultant en informatique",
        "Contrôleur (trains)",
        "Contrôleur aérien",
        "Contrôleur de gestion",
        "Contrôleur financier",
        "Courtier en assurance",
        "Cuisinier",
        "Décorateur d'intérieur",
        "Dentiste",
        "Designer industriel",
        "Développeur informatique",
        "Diacre",
        "Diététicien",
        "Directeur artistique",
        "Directeur des ventes",
        "Directeur financier",
        "Directeur RH",
        "Economiste",
        "Educateur de jeunes",
        "Eleveur",
        "Employé de banque",
        "Enseignant",
        "Ergothérapeute",
        "Esthéticienne",
        "Fleuriste",
        "Gardien",
        "Géomètre",
        "Gestionnaire immobilier",
        "Hôtesse de l'air",
        "Huissier",
        "Illustrateur",
        "Infirmière",
        "Ingénieur civil",
        "Ingénieur électronicien",
        "Ingénieur du BTP",
        "Inspecteur de l'action sanitaire et sociale",
        "Jardinier",
        "Jardinier paysagiste",
        "Journaliste",
        "Juge",
        "Kinésithérapeute",
        "Linguiste",
        "Machiniste",
        "Magasinier",
        "Maître d'hôtel",
        "Manipulateur radio",
        "Masseur",
        "Mécanicien aéronautique",
        "Médecin",
        "Moniteur d'auto-école",
        "Monteur électricien",
        "Nutritionniste",
        "Officier",
        "Opérateur de production",
        "Opérateur d'usinage sur commande numérique (UCN)",
        "Opticien",
        "Orthophoniste",
        "Personal Trainer",
        "Pharmacien",
        "Photographe",
        "Physicien",
        "Physicien médical",
        "Pilote",
        "Politicien",
        "Pompier",
        "Poseur de sol (solier)",
        "Prêtre",
        "Procureur",
        "Professeur des écoles",
        "Professeur d'éducation physique (EPS)",
        "Professeur de français langue étrangère",
        "Porfesseur des universités",
        "Psychologue",
        "Réceptionniste",
        "Réceptionniste d'hôtel",
        "Responsable communication",
        "Responsable grands comptes",
        "Responsable service clientèle",
        "Sages-femmes",
        "Secrétaire médicale",
        "Serveur",
        "Skipper",
        "Soldat",
        "Soudeur",
        "Statisticien",
        "Surveillant pénitentiaire",
        "Technicien alarme intrusion",
        "Technicien d'analyses biomédicales",
        "Technicien de maintenance informatique",
        "Technicien d'exploitation",
        "Téléconseiller",
        "Test manager",
        "Travailleur social",
        "Urbaniste",
        "Vendeur",
        "Vétérinaire",
        "Webmaster",
      ],
      cust: false,
      imageNps: "",
      profession: "",
      errorLogin: "",
      emailNew: "",
      name: "",
      success: false,
      deleted: false,
      signUpMod: false,
      signInMod: true,
      recorver: false,
      recSend: false,
      mobile: false,
      newl: false,
      errors: [],
    };
  },
  computed: {
    authenticated() {
      return localStorage.getItem("landsapp");
    },
    userCurr() {
      console.log("this.$store.state.auth", this.$store.state.auth);
      return this.$store.state.auth.userInfo;
    },
  },
  watch: {
    password() {
      this.has_minimum_lenth = this.password.length > 8;
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#$%^&*)(+=._-]/.test(this.password);
    },
    passwordRep() {
      if (this.password === this.passwordRep) {
        this.repeted = true;
      } else {
        this.repeted = false;
      }
    },
    userCurr(val) {
      console.log("val", val);
      this.firstname = val.firstname;
      this.imageNps = val.image;
    },
  },
  async mounted() {
    //navigator.geolocation.getCurrentPosition(this.showPosition)
    await this.getAllPost();
    if (localStorage.getItem("landsapp")) {
      await this.whoami();
    }
    if (localStorage.getItem("landsapp.errorPass")) {
      localStorage.removeItem("lands.errorPass");
      this.errorPass = true;
    }

    if (localStorage.getItem("lands.delete")) {
      localStorage.removeItem("lands.delete");
      this.deleted = true;

      setTimeout(() => {
        this.deleted = false;
      }, 15000);
    }

    if (localStorage.getItem("lands.newRegisterSucces")) {
      localStorage.removeItem("lands.newRegisterSucces");
      this.success = true;

      setTimeout(() => {
        this.success = false;
      }, 15000);
    }
  },
  methods: {
    ...mapActions({
      whoami: "auth/whoami",
      getAllPost: "posts/getAll",
      register: "auth/register",
      login: "auth/login",
      rec: "auth/rec",
      newletter: "auth/newLetter",
    }),
    createNew() {
      if (localStorage.getItem("landsapp")) {
        this.$router.push({ name: "post" });
      } else {
        this.signinMet();
      }
    },
    submit() {
      console.log("lv,rml,ml,");
      const data = {
        email: this.emailNew,
        name: this.name,
      };
      this.errors = [];
      schemaTwo
        .validate(data, { abortEarly: false })
        .then(async (valid) => {
          console.log(valid);
          if (valid) {
            await this.newletter(data).then(async (response) => {
              console.log(response);
              this.newl = true;
            });
          }
        })
        .catch((err) => {
          this.load = false;
          err.inner.forEach((e) => {
            console.log(e.message, e.path);
            this.errors.push(e.message);
          });
        });
    },

    openSet() {
      const All = document.getElementsByTagName("html")[0];
      var eventer = () => {
        console.log("il est appelé et je fais nimporte quoi");
        this.cust = false;
        All.removeEventListener("click", eventer);
      };
      if (this.cust) {
        this.cust = false;
        All.removeEventListener("click", eventer);
      } else {
        All.removeEventListener("click", eventer);
        this.cust = true;
        setTimeout(() => {
          All.addEventListener("click", eventer);
        }, 500);
      }
    },

    signOut() {
      localStorage.clear();
      location.reload();
    },

    async recover() {
      const data = {
        email: this.email,
      };
      await this.rec(data);
      this.recSend = true;
    },

    async registerMet() {
      const data = {
        firstname: this.firstname,
        lastname: this.lastname,
        username: this.username,
        password: this.password,
        email: this.email,
        phone: this.phone,
        profession: this.profession,
        role: 0,
      };

      await this.register(data);
      window.location.reload();
    },
    async loginMet() {
      const data = {
        username: this.username,
        password: this.password,
      };
      const res = await this.login(data);
      console.log(res, "ress");
      if (res.statusCode == 404) {
        this.errorLogin = "User not found";
        localStorage.setItem("lands.errorPass", true);
      } else if (res.statusCode == 403) {
        this.errorLogin = "Username or password failed";
        localStorage.setItem("lands.errorPass", true);
      } else {
        window.location.reload();
      }
    },
    bibouille(e) {
      console.log(e, "e");
      this.mobile = !this.mobile;
      /*setTimeout(() => {
        const famous = document.getElementsByClassName(
          "mmenu-init mm-menu mm-offcanvas mm-opened"
        );
        console.log("famous", famous[0]);
        famous[0].style.zIndex = 22222222;
      }, 300);*/
    },
    close() {
      this.mobile = !this.mobile;
    },
    signinMet() {
      this.signin = true;

      setTimeout(() => {
        const elPb = document.querySelectorAll(".mfp-ready");

        elPb.forEach((element) => {
          element.style.display = "none";
        });
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.mmenu-init .mm-menu .mm-offcanvas {
  z-index: 22222222 !important;
}
.nav_bar {
  background-color: rgba(255, 255, 255, 1);
  color: #334e6f;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

nav li:hover,
nav li:active {
}

.logo {
  background-repeat: no-repeat;
  background-image: url("/img/gros-plan-d-une-goutte-d-eau-sur-un-fond-degrade-vert-couvert-de-gouttes-d-eau-la-condensation-p3cpha.jpg");
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 900;
  font-size: 40px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 10px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.barner {
  background-image: url("/img/home-search-background1.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
}

#navigation.style-1 ul li a {
  color: white !important;
  cursor: pointer;
}

img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

#header {
  background: #013320 !important;
}
.woocommerce form .form-row .required {
  visibility: visible;
}
</style>
