<template>
  <div class="w-full px-0" style="padding: 0 !important">
    <div
      class="fs-inner-container content pt-96 xl:pt-36"
      style="padding-right: 16px; padding-left: 16px"
    >
      <div class="fs-content" :key="key">
        <!-- Search / End -->

        <section class="listings-container margin-top-30">
          <!-- Sorting / Layout Switcher -->
          <div class="row fs-switcher">
            <div class="col-md-6">
              <!-- Showing Results -->
              <p class="showing-results">
                {{ posts ? posts.length : 0 }} {{ $t("home.find") }}
              </p>
            </div>
          </div>

          <!-- Listings -->
          <div class="row fs-listings">
            <!-- Listing Item -->
            <div
              v-for="(post, index) in posts"
              :key="index"
              class="col-lg-6 col-md-12"
            >
              <a
                @click="oneClicked(post.id)"
                class="listing-item-container relative"
                data-marker-id="2"
              >
                <div class="listing-item">
                  <img :src="baseUrl + '/' + post.image" alt="" />
                  <div class="listing-item-details">
                    <ul>
                      <li>Friday, August 10</li>
                    </ul>
                  </div>
                  <div class="listing-item-content">
                    <h3>{{ post.title }}</h3>
                    <span>{{ post.address }}</span>
                  </div>
                  <span
                    v-if="authenticated && false"
                    @click.stop="addFavorite($event, post.id)"
                    class="like-icon absolute"
                    :class="{ liked: isFavorite(post.id) }"
                  ></span>
                </div>
              </a>
            </div>
            <!-- Listing Item / End -->
          </div>
          <!-- Listings Container / End -->

          <!-- Pagination Container -->
          <div class="row fs-listings">
            <div class="col-md-12">
              <!-- Pagination -->
              <div class="clearfix"></div>
              <div class="row">
                <div class="col-md-12">
                  <!-- Pagination -->
                  <div
                    class="pagination-container margin-top-15 margin-bottom-40"
                  >
                    <!--nav class="pagination">
                      <ul>
                        <li><a href="#" class="current-page">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li>
                          <a href="#"><i class="sl sl-icon-arrow-right"></i></a>
                        </li>
                      </ul>
                    </nav-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Pagination Container / End -->
        </section>
      </div>
    </div>

    <l-map
      style="height: 99vh; width: 45vw; right: 0px; z-index: 100; top: 80px"
      class="cah fixed"
      v-if="showMap"
      :zoom="zoom"
      :center="maps && maps.length !== 0 ? maps[0].coordonee : ''"
      :options="mapOptions"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker
        v-for="(coo, index) in maps"
        :key="index"
        :lat-lng="coo.coordonee ? coo.coordonee : currentCenter"
      >
        <l-popup>
          <div @click="innerClick">
            <div @click="oneClicked(coo.id)" class="flex">
              <div><img :src="baseUrl + '/' + coo.image" alt="" /></div>
              <h4 class="text-xl">{{ coo.title }}</h4>
            </div>
          </div>
        </l-popup>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
//import ProductCard from "@/components/productCard.vue";

import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LPopup } from "@vue-leaflet/vue-leaflet";
import { latLng } from "leaflet";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    //ProductCard,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data() {
    return {
      geojson: {
        type: "FeatureCollection",
        features: [
          // ...
        ],
      },
      geojsonOptions: {
        // Options that don't rely on Leaflet methods.
      },
      zoom: 13,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(47.41322, -1.219482),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      showMap: true,
      key: 1,
      search: "",
    };
  },
  methods: {
    ...mapActions({
      whoami: "auth/whoami",
      addFavoriteStore: "auth/addFavorite",
      getAllPost: "posts/getAll",
      getSearch: "posts/getSearch",
    }),

    ...mapGetters({ userNew: "auth/userInfo" }),
    isFavorite(value) {
      const favorite = localStorage.getItem("lands.pref").split(",");
      return favorite.includes(value.toString());
    },

    ...mapGetters({ userNew: "auth/userInfo" }),

    async addFavorite(event, id) {
      if (!this.isFavorite(id)) {
        await this.addFavoriteStore(id);
        this.key++;
      } else {
        await this.removeFavoriteStore(id);
      }
    },

    innerClick() {
      console.log("il clique");
    },
    oneClicked(id) {
      this.$router.push({ name: "postDetails", params: { id } });
    },
    setupSearch() {
      this.getSearch(this.search);
    },
  },
  async beforeMount() {
    const { circleMarker } = await import("leaflet/dist/leaflet-src.esm");

    // And now the Leaflet circleMarker function can be used by the options:
    this.geojsonOptions.pointToLayer = (feature, latLng) =>
      circleMarker(latLng, { radius: 8 });
    this.mapIsReady = true;
  },
  computed: {
    posts() {
      console.log("la valeur change");
      return this.$store.state.auth.userInfo
        ? this.$store.state.auth.userInfo.preferences
        : [];
    },
    authenticated() {
      return localStorage.getItem("landsapp");
    },
    maps() {
      const datas = this.$store.state.posts.all;
      let wil;
      if (datas && datas.length !== 0) {
        wil = datas.reverse().map((data) => {
          return {
            coordonee:
              data.latitude && data.longitude
                ? latLng(data.latitude, data.longitude)
                : null,
            id: data.id,
            image: data.image,
            title: data.title,
          };
        });
      }
      console.log(wil);
      return wil ? wil : datas;
    },
    center() {
      return latLng(
        parseFloat(this.$store.state.posts.all[0].latitude),
        parseFloat(this.$store.state.posts.all[0].longitude)
      );
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.getAllPost();
    await this.whoami();

    if (localStorage.getItem("land.search")) {
      this.search = localStorage.getItem("land.search");
      localStorage.removeItem("land.search");
      this.setupSearch();
    }

    setTimeout(() => {
      if (this.posts && this.posts.lenght == 0)
        this.$router.push({ name: "Not Found" });
    }, 2000);
  },
};
</script>
<style>
@media screen and (max-width: 992px) {
  .cah {
    display: none;
  }
}
@media screen and (max-width: 1240px) {
  .cah {
    height: 100vh !important;
    top: 0 !important;
  }
}
</style>
