<template>
  <div class="row">
    <div
      class="flex justify-center items-center w-screen flex-col"
      style="height: 600px"
    >
      <img
        class="footer-logo h-44"
        src="images/Color-logo-with-background.png"
        alt=""
      />

      <h3
        class="font-bold text-6xl red-orange-brown px-10"
        v-html="$t('auction')"
      ></h3>
      <!-- Countdown -->
      <div id="countdown" class="margin-top-10 margin-bottom-35"></div>
      <!-- Countdown / End -->
      <!--div class="fireworks-container"></div>
      <div class="fireworks-container"></div>
      <div class="countdown">
        <div>
          <div class="value days">0</div>
          <div class="label">days</div>
          <div class="yeardigit">0</div>
        </div>
        <div>
          <div class="value hours">0</div>
          <div class="label">hours</div>
          <div class="yeardigit">0</div>
        </div>
        <div>
          <div class="value minutes">0</div>
          <div class="label">minutes</div>
          <div class="yeardigit">0</div>
        </div>
        <div>
          <div class="value seconds">0</div>
          <div class="label">seconds</div>
          <div class="yeardigit">0</div>
        </div>
      </div-->
      <br />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.red-orange-brown {
  background-image: linear-gradient(
    to right,
    green,
    yellowgreen 33%,
    yellow 66%,
    gold
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
</style>
