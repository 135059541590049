<template>
  <div
    class="border cursor-pointer w-full flex justify-center items-center hover:text-white font-bold text-sm px-4 py-2"
    :class="[
      rounded ? 'rounded-3xl' : 'rounded',
      !brand ? 'text-red-500' : '',
      brand
        ? 'border-brand hover:bg-green-700 text-black'
        : 'border-red-500 hover:bg-red-500',
      full ? 'bg-brand text-white' : '',
    ]"
  >
    <ion-icon
      v-if="Icon !== ''"
      :name="Icon"
      class="mr-2 visible text-brand hover:text-white font-bold"
    />{{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Enregistrer",
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    brand: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    Icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss"></style>
