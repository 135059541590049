<template>
  <!-- Container -->
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mt-56">
        <div
          v-if="errors.length !== 0"
          class="notification bg-red-300 large margin-bottom-55"
        >
          <h4>Something whrong 🙂</h4>
          <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
        </div>
        <div id="add-listing" class="separated-form mb-10">
          <div class="add-listing-section margin-top-45 mb-3">
            <!-- Headline -->
            <div class="add-listing-headline">
              <h3><i class="sl sl-icon-docs"></i> Détails</h3>
            </div>

            <div class="row with-forms">
              <!-- Phone -->
              <div class="col-md-6">
                <h5>Prénom</h5>
                <input v-model="firstname" type="text" />
              </div>

              <!-- Email Address -->
              <div class="col-md-6">
                <h5>Nom</h5>
                <input v-model="lastname" type="text" />
              </div>
            </div>
            <!-- Row / End -->

            <div class="flex flex-wrap md:flex-nowrap justify-between">
              <p class="form-row form-row-wide mr-4 md:w-1/2">
                <label for="password1"
                  >Mot de passe :
                  <i class="im im-icon-Lock-2"></i>
                  <input
                    v-model="password"
                    class="input-text"
                    type="password"
                    name="password1"
                    @focus="passwordFocus = true"
                    id="password1"
                  />
                </label>
                <small
                  v-if="passwordFocus"
                  id="passwordHelp"
                  class="form-text text-muted"
                  >Le mot de passe doit contenir au moins
                  <div
                    :class="
                      has_minimum_lenth ? 'text-green-500' : 'text-red-400'
                    "
                  >
                    8 caractères
                  </div>
                  <div
                    :class="has_lowercase ? 'text-green-500' : 'text-red-400'"
                  >
                    Une lettre en minuscule
                  </div>
                  <div
                    :class="has_uppercase ? 'text-green-500' : 'text-red-400'"
                  >
                    Une lettre majuscule
                  </div>
                  <div :class="has_number ? 'text-green-500' : 'text-red-400'">
                    un chiffre
                  </div>
                  et
                  <div :class="has_special ? 'text-green-500' : 'text-red-400'">
                    un caractère spécial.
                  </div></small
                >
              </p>

              <p class="form-row form-row-wide ml-4 md:w-1/2">
                <label for="password2"
                  >Répéter le mot de passe:
                  <i class="im im-icon-Lock-2"></i>
                  <input
                    v-model="passwordRep"
                    class="input-text"
                    type="password"
                    name="password"
                    @focus="passwordRepFocus = true"
                    id="password"
                  />
                </label>
                <small
                  v-if="passwordRepFocus"
                  id="passwordHelp"
                  class="form-text text-muted"
                  :class="repeted ? 'text-green-500' : 'text-red-400'"
                  >Les deux mot de passe doivent être identiques
                </small>
              </p>
            </div>

            <div class="col-md-6">
              <h5>Téléphone</h5>
              <input v-model="phone" type="text" />
            </div>

            <div class="col-md-6">
              <h5>Choisir une nouvelle image de profil</h5>
              <input
                @change="previewFiles"
                class="cursor-pointer"
                ref="upload"
                type="file"
              />
            </div>

            <!-- Description -->

            <!-- Section -->

            <!-- Section / End -->

            <a @click="submitForm" class="button preview"
              >Enregistrer <i class="fa fa-arrow-circle-right"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Content / End -->
</template>

<script>
import axios from "axios";
//import Select from "../components/select.vue";
import { mapActions } from "vuex";
import * as yup from "yup";
//import PostForm from "@/components/postForm.vue";

let schema = yup.object().shape({
  id: yup.number(),
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  password: yup.string().required(),
  image: yup.string(),
  phone: yup.string().required(),
});

export default {
  name: "Sign",
  components: {
    //Select,
    //PostForm,
  },
  data() {
    return {
      tabs: "sell",
      errors: [],
      firstname: "",
      lastname: "",
      password: "",
      phone: "",
      image: "",
      passwordRep: "",
      has_minimum_lenth: false,
      has_number: false,
      has_lowercsae: false,
      has_uppercase: false,
      has_special: false,
      passwordFocus: false,
      passwordRepFocus: false,
      repeted: false,
      user: null,
    };
  },

  mounted() {
    this.user = this.$store.state.auth.userInfo;
    this.firstname = this.user ? this.user.firstname : "";
    this.lastname = this.user ? this.user.lastname : "";
    this.phone = this.user ? this.user.phone : "";
    console.log(
      "this.$store.state.auth.userInfo",
      this.$store.state.auth.userInfo
    );
    window.scrollTo(0, 0);
    if (!localStorage.getItem("landsapp")) {
      this.$router.push({ name: "Home" });
    }
  },
  watch: {
    password() {
      this.has_minimum_lenth = this.password.length > 8;
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#$%^&*)(+=._-]/.test(this.password);
    },
    passwordRep() {
      if (this.password === this.passwordRep) {
        this.repeted = true;
      } else {
        this.repeted = false;
      }
    },
    userInfo: function (val) {
      console.log("valeur qui change", val);
      this.firstname = val.firstname;
      this.lastname = val.lastname;
      this.phone = val.phone;
    },
  },
  methods: {
    ...mapActions({
      uploadfromStore: "utilities/sigle",
      createPost: "auth/updateProfile",
    }),
    previewFiles(e) {
      this.setImage(e.target.files[0]);
    },
    async uploadFile(file) {
      console.log("file", file);
      let formData = new FormData();
      formData.append("image", file);
      for (var p of formData) {
        console.log(p);
      }
      return await axios
        .post(
          process.env.VUE_APP_API_BASE_URL + "/uploads/multiple",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              responseType: "json",
            },
          }
        )
        .then((response) => {
          console.log("response", response.data);
          if (response.status == 201) {
            return response.data[0].filename;
          }
        });
    },
    async setImage(event) {
      console.log("event", event);
      this.image = await this.uploadFile(event);
      await console.log("this.image", this.image);
    },

    async submitForm() {
      console.log("this.$store.state.auth", this.$store.state.auth);
      const data = {
        firstname: this.firstname,
        lastname: this.lastname,
        password: this.password,
        phone: this.phone,
        image: this.image,
        id: this.userInfo.id,
      };
      schema
        .validate(data, { abortEarly: false })
        .then(async (valid) => {
          if (valid) {
            await this.createPost(data).then(async (response) => {
              console.log(response, "post");
              location.reload();
            });
          }
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          err.inner.forEach((e) => {
            //console.log(e.message, e.path);
            this.errors.push(e.message);
          });
        });
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.auth.userInfo;
    },
  },
};
</script>

<style lang="scss"></style>
