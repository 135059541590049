<template>
  <div class="flex flex-col-reverse relative p-2">
    <div class="end-icon" v-if="endIcon">Fcfa</div>
    <!-- @keypress="$emit('check-input')" -->
    <!-- @keypress="updateValue($event)" -->
    <!-- @input="updateValue($event)" -->
    <!-- @input="updateValue($event)" -->

    <!-- @keydown="validatorInput" -->
    <!-- @input="updateValue($event)" -->
    <!-- @keypress="$emit('check-input')" -->

    <input
      :x-autocompletetype="typeInput === 'date' ? 'cc-exp' : ''"
      :maxlength="typeInput === 'date' ? 7 : ''"
      :pattern="typeInput === 'date' ? '\d*' : ''"
      :class="[
        'w-auto p-1 focus:outline-none focus-visible:border-blue-500',
        tolowercase ? 'lowercase' : '',
        InputIsValid ? 'border-brand' : 'border-red-500',
        error ? 'border-red' : '',
      ]"
      :type="typeInput"
      ref="input"
      :inputmode="
        typeInput === 'date' || typeInput === 'numeric' || typeInput === 'card'
          ? 'numeric'
          : ''
      "
      autocorrect="false"
      spellcheck="false"
      :id="inputId"
      :value="value"
      @keypress="validatorInput"
      @input="updateValue($event)"
      @blur="InputBlur"
      :placeholder="placeholderText"
    />
    <!-- @keyup="$emit('check-input')" -->
    <label
      :class="[
        'flex',
        InputIsValid ? 'text-input' : 'text-red-500',
        error ? 'text-red' : '',
      ]"
      :for="inputId"
      >{{ label }}</label
    >
  </div>
</template>

<script>
import defaultTheme from "../temp";
export default {
  inject: ["getTheme"],
  data() {
    return {
      inputHasFocus: false,
      InputIsValid: true,
    };
  },
  props: {
    error: {
      default: false,
      type: Boolean,
    },
    endIcon: String,
    inputId: String,
    value: String,
    label: String,
    placeholder: String,
    tolowercase: {
      default: false,
      type: Boolean,
    },
    typeInput: {
      type: String,
      default: "alpha",
    },
  },
  // watch: {
  //   value: function(value){
  //     console.log('hello world',value)
  //   }
  // },
  methods: {
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    onPaste(e) {
      if (this.typeInput == "date") {
        const cvv = e.clipboardData.getData("text");
        if (!this.isNumeric(cvv)) {
          e.preventDefault();
        }
        if (this.isNumeric(cvv) === true && cvv.length === 4) {
          e.target.value = `${cvv[0]}${cvv[1]}/${cvv[2]}${cvv[3]}`;
          this.InputIsValid = e.target.value.length > 0 ? true : false;

          this.$emit("input", e.target.value);
        }
      }
    },
    InputBlur(e) {
      this.InputIsValid = e.target.value.length > 0 ? true : false;

      this.$emit("input", e.target.value);
    },
    updateValue(event) {
      // setImmediate
      setTimeout(() => {
        this.$emit("input", event.target.value);
        this.$emit("check-input");
      }, 0);
    },
    validatorInput(event) {
      // this.
      // this.error = false
      // this.$emit("input", event.target.value);
      // this.$emit('check-input')
      // this.$emit("input", event.target.value);
      if (this.typeInput == "card") {
        this.validateNumber(event);

        this.validateDateCardNumber(event);
      }
      if (this.typeInput == "alpha") {
        var value = String.fromCharCode(event.which);
        console.log(event.keyCode);

        var pattern = new RegExp(/[a-zåäö ]/i);
        if (!pattern.test(value) && event.keyCode !== 8) {
          event.preventDefault();
        }
      }
      if (this.typeInput == "date") {
        // event.preventDefault();
        this.validateDate(event);
      }
      if (this.typeInput == "numeric") {
        this.validateNumber(event);
      }

      // this.$emit('check-input',event)
    },
    validateNumber(e) {
      // var value = String.fromCharCode(event.which);
      // console.log({test:value})

      // var pattern = new RegExp("^(0|[1-9][0-9]*)$");
      // if (!pattern.test(value) && value !== '') {
      //   event.preventDefault();
      // }
      // var target = evt.target || evt.srcElement;
      // var charCode = (evt.which) ? evt.which : evt.keyCode;
      // // evt = (evt) ? evt : window.event;
      // // var charCode = (evt.which) ? evt.which : evt.keyCode;
      // console.log(charCode)
      // if ((charCode < 47 || charCode > 57 )  &&  charCode !== 8){
      //     event.preventDefault();
      // }
      //var target = e.target || e.srcElement;
      var charCode = e.which ? e.which : e.keyCode;
      if ((charCode < 47 || charCode > 57) && charCode !== 8) {
        e.preventDefault();
      }
      // var len = target.value.length;
      // console.log(len == 5 &&  charCode !== 8)
      // if (len == 5 &&  charCode !== 8) {
      //   e.preventDefault();
      // }

      // if (len !== 1 || len !== 3 || len == 5) {
      //   if (e.keyCode == 47) {
      //     e.preventDefault();
      //   }
      // }Z
    },
    validateDate() {
      //  console.log(e.shiftKey,'event.shiftKey')
      //   var target = e.target || e.srcElement;
      //   var charCode = (e.which) ? e.which : e.keyCode;
      //   // (charCode < 47 || charCode > 57 )  &&  charCode !== 8
      //   if ((charCode < 47 || charCode > 57 )  &&  charCode !== 8) {
      //     console.log('hhhhhh')
      //     e.preventDefault();
      //   }
      //   var len = target.value.length;
      //   console.log(len == 5 &&  charCode !== 8)
      //   console.log(charCode)
      //   if (len == 5 &&  charCode !== 8) {
      //     e.preventDefault();
      //   }
      //   if (len !== 1 || len !== 3 || len == 5) {
      //     if (e.keyCode == 47) {
      //       e.preventDefault();
      //     }
      //   }
      //  setTimeout(() => {
      //     console.log('sisi')
      //     console.log(len)
      //     console.log(charCode)
      //     if (len === 1 && charCode !== 8) {
      //       let v = (target.value += "/");
      //     }
      // console.log(this.value)
      // }, 0);
      // (target.value += "/")
    },
    delay() {
      return new Promise((resolve) => {
        return setTimeout(resolve, 0);
      });
    },
    async validateDateCardNumber(e) {
      console.log(await this.delay(), "promesse");

      console.log(e.target.value, "salut");
      var target = e.target || e.srcElement;
      var str = target.value.replace(/\s/g, "");

      var numberChunks = str.match(/.{1,4}/g);
      console.log(numberChunks, "uuuu");
      if (!numberChunks) {
        console.log("escape");
      }
      console.log(e.target.value, "target");
      var result = numberChunks ? numberChunks.join(" ") : "";
      console.log(result, "RESULT");
      target.value = result;
    },
  },
  mounted() {
    // console.log(this.FormInputLabel, "getTheme");
    //const r =  document.querySelector('input').value
    setTimeout(() => {
      // console.log(this.value,'OKOKOKO')
      if (this.value.length > 0) {
        this.InputIsValid = true;
      }
    }, 0);
  },

  //
  computed: {
    placeholderText() {
      return this.placeholder;
      // if (this.typeInput==='date') {
      //   return  'MM/AA'
      // }
      // else if (this.typeInput === 'card') {
      //   return 'Card ID'
      // }
    },
    default_theme() {
      let defaultTheme = {
        color: "#4e6bfc",
        borderColor: "#4e6bfc",
      };
      return defaultTheme;
    },
    theme() {
      return {};
    },
    FormInputLabelInFocus() {
      return {
        ...defaultTheme.FormInputLabelInFocus,
        ...this.default_theme,
        ...this.theme.FormInputLabelInFocus,
      };
    },
    FormInputLabel() {
      return {
        ...defaultTheme.FormInputLabel,
        // ...this.default_theme,
        ...this.theme.FormInputLabel,
      };
    },
    FormInputFocus() {
      return {
        ...defaultTheme.FormInputFocus,
        ...this.default_theme,
        ...this.theme.FormInputFocus,
      };
    },
  },
};
</script>

<style scoped>
/* input {
  transition: all 0.3s;
  color: #666;
}
input:focus + label {
  color: rgb(78, 107, 252);
  font-weight: bold;
}
input:focus {
  border-color: #4e6bfc;
} */

.end-icon {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  margin-top: 12px;
  color: #666;
  margin-right: 10px;
}

input {
  border: 2px solid #a5bce0;
  box-sizing: border-box;
  border-radius: 6px;
}

/* .uk-default-input {
  border: 1.5px solid #A5BCE0;
  box-sizing: border-box;
  border-radius: 6px;
} */

/* .uk-focus-input {
  border: 1.5px solid #4E6BFC;
  box-sizing: border-box;
  border-radius: 6px;
} */

.uk-valid-input {
  border: 2px solid #a5bce0;
  box-sizing: border-box;
  border-radius: 6px;
}

input:focus {
  border: 2px solid #4e6bfc;
  box-sizing: border-box;
  border-radius: 6px;
}

.uk-valid-label {
  color: #293340;
}

/* @keyframes shake {
  0%  { transform:     translate(2px, 1px) rotate(0deg); }
  10% { transform:     translate(-1px, -2px) rotate(-2deg); }
  20% { transform:     translate(-3px, 0px) rotate(3deg); }
  30% { transform:     translate(0px, 2px) rotate(0deg); }
  40% { transform:     translate(1px, -1px) rotate(1deg); }
  50% { transform:     translate(-1px, 2px) rotate(-1deg); }
  60% { transform:     translate(-3px, 1px) rotate(0deg); }
  70% { transform:     translate(2px, 1px) rotate(-2deg); }
  80% { transform:     translate(-1px, -1px) rotate(4deg); }
  90% { transform:     translate(2px, 2px) rotate(0deg); }
  100%{ transform:     translate(1px, -2px) rotate(-1deg); }
 } */
</style>
