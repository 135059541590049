<template>
  <div>
    <section
      class="fullwidth pt-56 padding-top-75"
      data-background-color="#f9f9f9"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-md-offset-2">
            <h3 class="headline centered headline-extra-spacing">
              <strong class="headline-with-separator">Notre équipe</strong>
            </h3>
          </div>
        </div>

        <div class="row icons-container">
          <!-- Stage -->
          <div class="col-md-6 cursor-pointer">
            <div class="icon-box-2 with-line" style="min-height: 500px">
              <img
                src="images/boris.JPG"
                class="rounded-full"
                style="height: 200px; width: 200px"
                alt=""
              />

              <h3>Boris Houenou, Ph.D.</h3>
              <h3>Managing director, Co-founder</h3>
              <p>
                is a US-trained economist with a Ph.D. who has spearheaded
                several industry transformations in Africa, including
                agribusiness and tech. He is active in the space of mobilizing
                artificial intelligence, technology, and economics to solve key
                problems standing between Africa and prosperity.
              </p>
            </div>
          </div>

          <!-- Stage -->

          <!-- Stage -->
          <div class="col-md-6">
            <div class="icon-box-2" style="min-height: 500px">
              <img
                src="images/1517394269539.jpg"
                class="rounded-full"
                style="height: 200px; width: 200px"
                alt=""
              />

              <h3>Dr. Ir. Elie Antoine Padonou, Ph.D.</h3>
              <h3>Soil Scientist, Co-founder</h3>
              <p>
                has held PhD on natural resources management since 2015. He is
                expert on land degradation & restoration, land use land cover
                change analysis, forest conservation & management, forest
                ecology, agroforestry and agronomy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="fullwidth padding-bottom-70"
      data-background-color="#f9f9f9"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-md-offset-2">
            <h3 class="headline centered headline-extra-spacing"></h3>
          </div>
        </div>

        <div class="row icons-container">
          <!-- Stage -->
          <div class="col-md-6 cursor-pointer">
            <div class="icon-box-2 with-line" style="min-height: 500px">
              <img
                src="images/1607127239330.jpg"
                class="rounded-full"
                style="height: 200px; width: 200px"
                alt=""
              />
              <h3>Sophonie Jed KOBOUDE</h3>
              <h3>Business developer, Co-founder</h3>
              <p>
                is an engineer graduated from CentraleSupélec in Paris and an
                economist graduated from the Conservatoire National des Arts et
                Métiers in Paris. He has worked with several startups in Africa.
                He is passionate about agriculture, artificial intelligence and
                Africa.
              </p>
            </div>
          </div>

          <!-- Stage -->
          <div class="col-md-6 cursor-pointer">
            <div class="icon-box-2 with-line" style="min-height: 500px">
              <img
                src="images/wilfried.jpg"
                class="rounded-full"
                style="height: 200px; width: 200px"
                alt=""
              />

              <h3>Wilfried Dimitri HOUEDANOU</h3>
              <h3>Chief Technical Officer, Co-founder</h3>
              <p>
                is a senior web developer with experience having evolved in the
                technology community in Africa and Europe and contributor to
                several opensource projects. He is passionate about agriculture,
                design and Africa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scroll(0, 0);
  },
};
</script>
