<script>
import { mapActions } from "vuex";

export default {
  props: {
    token: {
      require: true,
    },
  },

  async mounted() {
    window.scrollTo(0, 0);
    await this.verify(this.token);
    location.href = "/";
    //this.$router.push({name: "Home"})
  },
  methods: {
    ...mapActions({
      verify: "auth/verify",
    }),
  },
};
</script>
